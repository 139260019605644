import apiClient from '@/services/api/apiClient'
import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import {
  Order, OrdersState
} from '@/store/modules/orders/types'
import { PageInfo } from '@/main.types'

export const actions: ActionTree<OrdersState, RootState> = {
  async getOrders ({ getters },
    payload: { page: number, orderNumberOrPhone: string }
  ): Promise<{ payload: Order[], pageInfo: PageInfo }> {
    const {
      page = 0,
      orderNumberOrPhone
    } = payload || {}

    const { data } = await apiClient.get('/order/getAllOrdersAdmin', {
      params: {
        page,
        orderNumberOrPhone
      }
    })

    return {
      payload: data.payload.map(el => getters.deserializeOrder(el)),
      pageInfo: data.pageInfo
    }
  }
}

export default actions
