























import Vue from 'vue'
import {
  mapState,
  mapActions
} from 'vuex'
import Baskets from '@/components/Baskets.vue'

export default Vue.extend({
  name: 'ModalBaskets',

  components: {
    Baskets
  },

  data () {
    return {
      modalShown: false
    }
  },

  computed: {
    ...mapState('baskets', [
      'baskets'
    ])
  },

  watch: {
    modalShown (val) {
      if (!val) return

      if (!this.baskets.length) {
        this.getBaskets({
          pageSize: 100
        })
      }
    }
  },

  methods: {
    ...mapActions('baskets', [
      'getBaskets'
    ]),

    handleSelectBasket () {
      this.$bvModal.hide('modal-baskets')
    },

    async onShownModal () {
      this.modalShown = true
    },

    onHiddenModal () {
      this.modalShown = false
    }
  }
})
