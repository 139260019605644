import Vue from 'vue'

Vue.filter('unit', (value: string) => {
  switch (value) {
    case 'g':
      return 'г.'
    case 'ml':
      return 'мл.'
    default:
      return 'г.'
  }
})

Vue.filter('declination', (number: number, words: [string]) => {
  return `${number} ${words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]]}`
})

Vue.filter('dishType', (value: string) => {
  switch (value) {
    case 'breakfast':
      return 'завтрак'
    case 'lunch':
      return 'обед'
    case 'dinner':
      return 'ужин'
    case 'snack':
      return 'перекус'
    default:
      return ''
  }
})
