










import Vue, { PropType } from 'vue'
export default Vue.extend({
  name: 'CLoading',

  props: {
    fullscreen: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    size: {
      type: Number as PropType<number>,
      default: () => 3
    }
  }
})
