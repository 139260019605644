import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
import authGuard from '@/router/guards/auth-guard'
import routeGuard from '@/router/guards/route-guard'
import checkoutOrderGuard from '@/router/guards/checkout-order-guard'

import {
  ROLE_GUEST,
  ROLE_USER,
  ROLE_MANAGER,
  ROLE_ADMIN
} from '@/common/constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      fullWidth: true,
      title: 'Сервис по доставке продуктов с продуманными рецептами',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue')
  },

  {
    path: '/delivery',
    name: 'delivery',
    meta: {
      title: 'Доставка',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "delivery" */ '@/pages/Delivery.vue')
  },

  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Политика конфиденциальности',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/pages/PrivacyPolicy.vue')
  },

  {
    path: '/public-offer',
    name: 'public-offer',
    meta: {
      title: 'Публичная оферта',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "public-offer" */ '@/pages/PublicOffer.vue')
  },

  {
    path: '/custom-basket',
    name: 'custom-basket',
    component: () => import(/* webpackChunkName: "basket-page" */ '@/pages/Basket.vue'),
    meta: {
      fullWidth: true,
      title: 'Собрать свою корзину',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    }
  },

  {
    path: '/nutrition/:id?',
    name: 'nutrition',
    component: () => import(/* webpackChunkName: "nutrition" */ '@/pages/Nutrition.vue'),
    meta: {
      fullWidth: true,
      title: 'План питания',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    children: [
      {
        name: 'nutrition-checkout',
        path: 'checkout',
        meta: {
          fullWidth: true,
          title: 'Оформление заказа',
          roles: [
            ROLE_GUEST,
            ROLE_USER,
            ROLE_MANAGER,
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "nutrition-checkout" */ '@/pages/Checkout/index.vue')
      }
    ]
  },

  {
    path: '/basket/:id?',
    name: 'basket',
    component: () => import(/* webpackChunkName: "basket-page" */ '@/pages/Basket.vue'),
    meta: {
      fullWidth: true,
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    }
    // children: [
    //   {
    //     name: 'basket-dishes',
    //     path: '',
    //     meta: {
    //       fullWidth: true,
    //       title: 'Готовая корзина',
    //       roles: [
    //         ROLE_GUEST,
    //         ROLE_USER,
    //         ROLE_MANAGER,
    //         ROLE_ADMIN
    //       ]
    //     },
    //     component: () => import(/* webpackChunkName: "basket-dishes" */ '@/components/Dishes.vue')
    //   },
    //   {
    //     name: 'basket-products',
    //     path: 'products',
    //     meta: {
    //       fullWidth: true,
    //       title: 'Список продуктов',
    //       roles: [
    //         ROLE_GUEST,
    //         ROLE_USER,
    //         ROLE_MANAGER,
    //         ROLE_ADMIN
    //       ]
    //     },
    //     component: () => import(/* webpackChunkName: "basket-products" */ '@/components/Products.vue')
    //   }
    // ]
  },

  {
    path: '/order/:id',
    name: 'order',
    component: () => import(/* webpackChunkName: "basket-order" */ '@/pages/Basket.vue'),
    meta: {
      fullWidth: true,
      title: 'Заказ',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    children: [
      // {
      //   name: 'order-dishes',
      //   path: '',
      //   meta: {
      //     fullWidth: true,
      //     title: 'Заказ',
      //     roles: [
      //       ROLE_GUEST,
      //       ROLE_USER,
      //       ROLE_MANAGER,
      //       ROLE_ADMIN
      //     ]
      //   },
      //   component: () => import(/* webpackChunkName: "order-dishes" */ '@/components/Dishes.vue')
      // },
      // {
      //   name: 'order-products',
      //   path: 'products',
      //   meta: {
      //     fullWidth: true,
      //     title: 'Список продуктов',
      //     roles: [
      //       ROLE_GUEST,
      //       ROLE_USER,
      //       ROLE_MANAGER,
      //       ROLE_ADMIN
      //     ]
      //   },
      //   component: () => import(/* webpackChunkName: "order-products" */ '@/components/Products.vue')
      // },
      {
        name: 'order-checkout',
        path: 'checkout',
        meta: {
          fullWidth: true,
          title: 'Оформление заказа',
          roles: [
            ROLE_USER,
            ROLE_MANAGER,
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "checkout" */ '@/pages/Checkout/index.vue')
      }
    ]
  },

  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/pages/Profile/index.vue'),
    children: [
      {
        name: 'profile',
        path: '',
        meta: {
          private: true,
          title: 'Профиль',
          roles: [
            ROLE_USER,
            ROLE_MANAGER,
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-user" */ '@/pages/Profile/User.vue')
      },
      {
        name: 'profile-orders',
        path: 'orders',
        meta: {
          private: true,
          title: 'Мои заказы',
          roles: [
            ROLE_USER,
            ROLE_MANAGER,
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-orders" */ '@/pages/Profile/Orders.vue')
      }
    ]
  },
  {
    path: '/profile/order/:id',
    name: 'profile-order-detail',
    meta: {
      private: true,
      title: 'Детали заказа',
      fullWidth: true,
      roles: [
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "profile-order-detail" */ '@/pages/Profile/Order.vue')
  },
  {
    path: '/admin/orders/:id',
    name: 'admin-order-detail',
    meta: {
      layout: 'admin',
      title: 'Детали заказа',
      fullWidth: true,
      roles: [
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "admin-order-detail-page" */ '@/pages/admin/OrderDetailPage.vue')
  },

  {
    path: '/order/:id/payment-success',
    name: 'payment-success',
    meta: {
      title: 'Успешная оплата'
    },
    component: () => import(/* webpackChunkName: "payment-success" */ '@/pages/System/PaymentSuccess.vue')
  },

  {
    path: '/order/:id/payment-failure',
    name: 'payment-failure',
    meta: {
      title: 'Неуспешная оплата',
      roles: [
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "payment-failure" */ '@/pages/System/PaymentFailed.vue')
  },

  {
    path: '/order/:id/success',
    name: 'order-success',
    meta: {
      title: 'Заказ оформлен',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "order-success" */ '@/pages/System/OrderSuccess.vue')
  },

  {
    path: '/order-products/:id',
    name: 'order-products-list',
    meta: {
      title: 'Список продуктов',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "order-products-list" */ '@/pages/System/OrderProductsList.vue')
  },

  {
    path: '/print-order-recipes/:id',
    name: 'print-order-recipes',
    meta: {
      fullWidth: true,
      title: 'Печать рецептов заказа',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "print-order-recipes" */ '@/pages/System/PrintOrderRecipes.vue')
  },

  {
    path: '/admin',
    name: 'admin',
    redirect: '/admin/products',
    roles: [
      ROLE_ADMIN
    ]
  },

  {
    path: '/admin/products',
    name: 'admin-products',
    meta: {
      layout: 'admin',
      title: 'Продукты',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-products" */ '@/pages/admin/Products/index.vue')
  },

  {
    path: '/admin/dishes',
    name: 'admin-dishes',
    meta: {
      layout: 'admin',
      title: 'Блюда',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-dishes" */ '@/pages/admin/DishesList/index.vue')
  },

  {
    path: '/admin/dishes/edit/:id?',
    name: 'admin-dishes-edit',
    meta: {
      layout: 'admin',
      title: 'Редактирование/создание блюда',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-dishes-edit" */ '@/pages/admin/Dish/index.vue')
  },

  {
    path: '/admin/dishes-categories',
    name: 'admin-dishes-categories',
    meta: {
      layout: 'admin',
      title: 'Подборки блюд',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-dishes-categories" */ '@/pages/admin/DishesCategories/DishesCategories.vue')
  },

  {
    path: '/admin/dishes-categories/edit/:id?',
    name: 'admin-dishes-category-edit',
    meta: {
      layout: 'admin',
      title: 'Редактирование/создание подборки блюд',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-dishes-category-edit" */ '@/pages/admin/DishesSubCategory/DishesSubCategory.vue')
  },

  {
    path: '/admin/baskets',
    name: 'admin-baskets',
    meta: {
      layout: 'admin',
      title: 'Корзины',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-baskets" */ '@/pages/admin/Baskets/Baskets.vue')
  },

  {
    path: '/admin/baskets/edit/:id?',
    name: 'admin-baskets-edit',
    meta: {
      fullWidth: true,
      layout: 'admin',
      title: 'Редактирование/создание корзины',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-baskets-edit" */ '@/pages/admin/Basket/Basket.vue')
  },

  {
    path: '/admin/orders',
    name: 'admin-orders',
    meta: {
      layout: 'admin',
      title: 'Заказы',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-orders" */ '@/pages/admin/OrdersList/index.vue')
  },

  {
    path: '/admin/orders/:id',
    name: 'admin-orders-edit',
    meta: {
      layout: 'admin',
      title: 'Детали заказа',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-orders-edit" */ '@/pages/admin/OrdersList/index.vue')
  },

  {
    path: '/admin/catalogs',
    name: 'admin-catalogs',
    redirect: '/admin/catalogs/product-types',
    meta: {
      private: true,
      layout: 'admin',
      title: 'Справочники',
      roles: [
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-catalogs" */ '@/pages/admin/Сatalogs/index.vue'),
    children: [
      {
        name: 'admin-shops',
        path: 'shops',
        meta: {
          private: true,
          layout: 'admin',
          title: 'Магазины',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "admin-shops" */ '@/pages/admin/Shops/index.vue')
      },
      {
        name: 'admin-product-types',
        path: 'product-types',
        meta: {
          private: true,
          layout: 'admin',
          title: 'Типы продуктов',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "admin-product-types" */ '@/pages/admin/ProductTypes/index.vue')
      }
    ]
  },

  {
    path: '/faq',
    name: 'faq',
    meta: {
      title: 'FAQ',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    // route level code-splitting
    // this generates a separate chunk (page.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin-catalogs" */ '@/pages/FAQ.vue')
  },

  // Посадочная страница для детских садов
  {
    path: '/kinder',
    name: 'kindergarten-landing',
    meta: {
      fullWidth: true,
      title: 'Для детских садов',
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "kinder" */ '@/pages/Landing/index.vue')
  },

  {
    path: '/admin/meal-plans',
    name: 'admin-meal-plans',
    meta: {
      title: 'Планы питания',
      layout: 'admin',
      roles: [
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "admin-meal-plans" */ '@/pages/admin/MealPlans/MealPlans.vue')
  },

  {
    path: '*',
    name: 'page-not-found',
    meta: {
      roles: [
        ROLE_GUEST,
        ROLE_USER,
        ROLE_MANAGER,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "admin-orders-edit" */ '@/pages/PageNotFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // scrollBehavior () {
  //   return { x: 0, y: 0 }
  // },
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach(multiguard([
  checkoutOrderGuard,
  authGuard,
  routeGuard
]))

export default router
