




































import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
  Swiper, SwiperSlide
} from 'vue-awesome-swiper'
import { DEFAULT_CATEGORY_NAME } from '@/common/constants'
import Logo from '@/components/Logo.vue'
import windowWidth from '@/mixins/windowWidth'

export default Vue.extend({
  name: 'CategoriesList',

  components: {
    Swiper,
    SwiperSlide,
    Logo
  },

  mixins: [
    windowWidth
  ],

  props: {
    baskets: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      selectedCategory: DEFAULT_CATEGORY_NAME,
      defaultCategoryName: DEFAULT_CATEGORY_NAME,
      swiperOptions: {
        speed: 450,
        slidesPerView: 'auto',
        spaceBetween: 20
      }
    }
  },

  computed: {
    ...mapGetters('baskets', [
      'basketsCategories'
    ]),

    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },

  watch: {
    windowWidth (val) {
      if (val <= 576) {
        this.swiper.params.spaceBetween = 8
        this.swiper.params.speed = 200
      } else if (val <= 992) {
        this.swiper.params.spaceBetween = 15
      }
    }
  },

  mounted () {
    this.$emit('select-category', this.defaultCategoryName)
  },

  methods: {
    handleSelectCategory (category) {
      this.selectedCategory = category
      this.$emit('select-category', category.name)
    }
  }
})
