






import Vue from 'vue'

export default Vue.extend({
  name: 'LogoItem',

  props: {
    imgUrl: {
      type: String,
      default: () => null
    }
  },

  computed: {
    url () {
      return this.imgUrl || require('@/assets/images/logo-colored.png')
    }
  }
})
