import Vue from 'vue'
import {
  ROLE_GUEST,
  ROLE_USER
} from '@/common/constants'
import { MutationTree } from 'vuex'
import * as authService from '@/services/auth'
import { getInitialAuthText } from './state'
import {
  AuthState,
  AuthText,
  User
} from './types'

const mutations: MutationTree<AuthState> = {
  setAuth (state, payload) {
    state.isAuthenticated = true
    state.user = payload

    if (payload.sessionId) {
      authService.setAccess({
        accessToken: payload.sessionId
      })
    }
  },

  setUser (state, data: User) {
    state.user = data

    authService.setAccess({
      accessToken: authService.getAccess().accessToken,
      role: data.role ? data.role : ROLE_USER
    })
  },

  updateUser (state, payload: User) {
    for (const [key, value] of Object.entries(payload)) {
      Vue.set(state.user, key, value)
    }
  },

  removeAuth (state) {
    state.user = {
      name: '',
      address: '',
      id: '',
      sessionId: '',
      role: ROLE_GUEST
    }
    state.isAuthenticated = false
  },

  setAuthText (state, data: AuthText) {
    state.authText = data
  },

  setDefaultAuthText (state) {
    state.authText = getInitialAuthText()
  }
}

export default mutations
