import { render, staticRenderFns } from "./SocialLinks.vue?vue&type=template&id=4caf8044&scoped=true&lang=pug&"
import script from "./SocialLinks.vue?vue&type=script&lang=ts&"
export * from "./SocialLinks.vue?vue&type=script&lang=ts&"
import style0 from "./SocialLinks.vue?vue&type=style&index=0&id=4caf8044&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4caf8044",
  null
  
)

export default component.exports