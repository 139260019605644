import { GetterTree } from 'vuex'
import {
  Product,
  ProductType,
  ProductsState,
  SerializedProduct
} from './types'
import { RootState } from '@/store/types'
import { PRODUCT_UNITS } from '@/common/constants'

const getters: GetterTree<ProductsState, RootState> = {
  getNewProduct: () => (): Product => ({
    package: 0,
    name: '',
    image: null,
    unit_id: { value: 'г.' },
    id: null,
    cost: 0,
    category: {
      id: null
    },
    producer: null,
    initPackage: 0,
    initCost: 0,
    productReplacement_id: {
      id: null,
      value: null
    },
    shop: {
      id: null,
      value: null
    },
    productFromVkusvill_id: {
      id: null,
      name: null,
      image: null
    },
    proteins: 0,
    calories: 0,
    carbohydrates: 0,
    fats: 0
  }),

  getNewProductType: () => (): ProductType => ({
    id: null,
    value: ''
  }),

  serializeProduct: () => (data: Product): SerializedProduct => {
    if (data.image instanceof File) throw new Error('image must be url string')
    if (data.image?.includes('blob')) throw new Error('image should not be blob url')

    return ({
      package: data.package.toString(),
      name: data.name,
      image: data.image,
      unit_id: PRODUCT_UNITS.find(el => el.text === data.unit_id.value)?.id,
      id: data.id,
      cost: data.cost.toString(),
      category_id: data.category.id ?? '',
      producer: data.producer,
      isCostChanged: +data.cost !== +data.initCost,
      isPackageChanged: +data.package !== +data.initPackage,
      productReplacement_id: data.productReplacement_id.id ?? '',
      shop_id: data.shop.id ?? '',
      productFromVkusvill_id: data.productFromVkusvill_id.id ?? '',
      proteins: data.proteins || 0,
      calories: data.calories || 0,
      carbohydrates: data.carbohydrates || 0,
      fats: data.fats || 0,
      note: data.note || ''
    })
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deserializeProduct: () => (data: any): Product => {
    return ({
      package: +data.package,
      initPackage: +data.package,
      name: data.name,
      image: data.image,
      unit_id: {
        value: data.unit_id?.value
      },
      id: data.id,
      cost: +data.cost,
      note: data.note,
      initCost: +data.cost,
      category: {
        id: data.category_id?.id
      },
      producer: data.producer,
      productReplacement_id: {
        id: data.productReplacement_id?.id,
        value: data.productReplacement_id?.value
      },
      originalProduct: data.originalProduct_id
        ? {
            category: {
              id: data.originalProduct_id?.category_id.id,
              name: data.originalProduct_id?.category_id.name
            },
            cost: data.originalProduct_id.cost,
            note: data.originalProduct_id.note,
            id: data.originalProduct_id.id,
            image: data.originalProduct_id.image,
            name: data.originalProduct_id.name,
            package: data.originalProduct_id.package,
            producer: data.originalProduct_id.producer,
            shop: {
              id: data.originalProduct_id?.shop_id.id,
              value: data.originalProduct_id?.shop_id.value
            },
            unit_id: {
              id: data.originalProduct_id?.unit_id.id,
              value: data.originalProduct_id?.unit_id.value
            }
          }
        : null,
      shop: {
        id: data.shop_id?.id,
        value: data.shop_id?.value
      },
      productFromVkusvill_id: {
        id: data.productFromVkusvill_id?.id,
        name: data.productFromVkusvill_id?.name,
        image: data.productFromVkusvill_id?.image
      },
      proteins: data.proteins,
      calories: data.calories,
      carbohydrates: data.carbohydrates,
      fats: data.fats
    })
  }
}

export default getters
