













import Vue, { PropType } from 'vue'
import BasketsCarousel from '@/components/BasketsCarousel.vue'
import BasketsCategories from '@/components/BasketsCategories.vue'
import { Basket } from '@/store/modules/baskets/types'

export default Vue.extend({
  name: 'HomeNew',

  components: {
    BasketsCarousel,
    BasketsCategories
  },

  props: {
    items: {
      type: Array as PropType<Basket[]>,
      required: true
    }
  },

  data () {
    return {
      selectedCategoryName: null
    }
  }
})
