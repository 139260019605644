import apiClient from '@/services/api/apiClient'
import {
  DOMAIN_URL, PAYMENT_API_URL, TERMINAL_KEY
} from '@/common/constants'
import {
  PaymentInitResponse, PaymentReceipt
} from '@/store/modules/payment/index'

export default {
  async createPayment (context, data: {
    orderId: string
    Phone: string
    basketName: string
    sum: number
  }) {
    try {
      // Раскоментить когда нужно будет отправлять список товаров а не 1 корзину, и удалить нижний items
      // const items: PaymentItems[] = data.products
      //   .filter(item => item.quantityPcs)
      //   .map(el => ({
      //     Name: el.product_id.name,
      //     Quantity: el.quantityPcs,
      //     Price: el.price * 100,
      //     Amount: el.totalPrice * 100,
      //     Tax: 'none'
      //   }))
      const items = [{
        Name: data.basketName,
        Quantity: 1,
        Price: data.sum * 100,
        Amount: data.sum * 100,
        Tax: 'none'
      }]
      const domainUrl: string = DOMAIN_URL
      return await apiClient.post(PAYMENT_API_URL, {
        TerminalKey: TERMINAL_KEY,
        OrderId: data.orderId,
        // OrderId: '',
        Amount: data.sum * 100,
        // Константу DOMAIN_URL меняем либо на свой локалхост либо на домен
        SuccessURL: `${domainUrl}/order/${data.orderId}/payment-success`,
        FailURL: `${domainUrl}/order/${data.orderId}/payment-failure`,
        // Раскоментить когда будет везде проставлена цена и кол-во у продуктов
        Receipt: {
          Phone: `+${data.Phone}`,
          Taxation: 'usn_income_outcome',
          Items: items
        } as PaymentReceipt
      }).then(res => res.data)
        .then((responseData: PaymentInitResponse) => {
          if (responseData.Success) {
            window.location.href = responseData.PaymentURL
          }
          return responseData
        })
    } catch (error) {
      return { error: error.response.data }
    }
  }
}
