

































































































import { Basket } from '@/store/modules/baskets/types'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'BasketCard',

  props: {
    basket: {
      type: Object as PropType<Basket>,
      default: () => ({} as Basket)
    },

    hasDaysVariations: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    editPrefix: {
      type: String as PropType<string>,
      default: ''
    },

    showEditButtons: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    showStatus: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    showDescription: {
      type: Boolean as PropType<boolean>,
      default: true
    },

    mini: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  methods: {
    handleClick () {
      this.$emit('onClick', this.basket)
    }
  }
})
