import apiClient from '@/services/api/apiClient'
import {
  Basket,
  ModalDishesOptions
} from '@/store/modules/baskets/types'
import {
  IdNameItem, PageInfo
} from '@/main.types'
import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { BasketsState } from './types'
import {
  objectToFormData
  // sortArrayByOrderIndex
} from '@/utils/functions'

export const actions: ActionTree<BasketsState, RootState> = {
  async getBaskets (
    { commit },
    payload: {
      page: number
      pageSize: number
      isKindergarten: boolean
    }
  ): Promise<{ payload: Basket[], pageInfo: PageInfo }> {
    const {
      page = 0,
      pageSize = 30,
      isKindergarten = false
    } = payload || {}

    const { data } = await apiClient.get('/basket/getAllBaskets', {
      params: {
        page,
        pageSize,
        isKindergarten
      }
    })

    const sorteredArray = data.payload.slice().sort((a: { sortIndex: string }, b: { sortIndex: string }) => {
      const aSortIndex = a.sortIndex ? Number(a.sortIndex) : 0
      const bSortIndex = b.sortIndex ? Number(b.sortIndex) : 0
      return (aSortIndex > bSortIndex) ? 1 : -1
    })

    commit('setBaskets', sorteredArray)

    return {
      payload: sorteredArray,
      pageInfo: data.pageInfo
    }
  },

  async getBasket ({
    commit,
    getters
  }, basketId: string) {
    try {
      const res = await apiClient.get(`/basket/getBasket?basketID=${basketId}`)
      // const basket = res.data.payload[0]

      const deserializedBasket = getters.deserializeBasket(res.data.payload[0])

      // if (basket.basketAuthorLogo) basket.basketAuthorLogo = basket.basketAuthorLogo
      commit('setCurrentBasket', deserializedBasket)
      return deserializedBasket
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async addBasket ({ getters }, payload: Basket) {
    const serializedBasket = getters.serializeBasket(payload)
    const formData = objectToFormData(serializedBasket)

    return await apiClient.post(
      '/basket/addBasket',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },

  async editBasket ({ getters }, payload: Basket) {
    const serializedBasket = getters.serializeBasket(payload)
    // delete payload.mealStyle

    const formData = objectToFormData(serializedBasket)

    return await apiClient.post(
      '/basket/editBasket',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  },
  async removeBasket (context, basketId: string) {
    try {
      const res = await apiClient.post('/basket/removeBasket', basketId)
      return res
    } catch (error) {
      return { error: error.response.data }
    }
  },
  async addDishInBasket (context, data) {
    try {
      const res = await apiClient.post('/dishesInBasket/addDishesInBasket', data)
      return res.data.result[0]
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async removeDishInBasket (context, dishId: string) {
    try {
      const res = await apiClient.post('/dishesInBasket/removeDishesInBasket', { id: dishId })
      return res.data.result[0]
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async setDishInBasketPortions (context, payload: {
    dishId: string
    newNumberOfPortions: number
  }) {
    const { data } = await apiClient.post(
      '/dishesInBasket/setDishInBasketPortions',
      {
        id: payload.dishId,
        newNumberOfPortions: payload.newNumberOfPortions
      }
    )

    return data
  },

  resetCurrentBasket ({
    commit, getters
  }) {
    commit('resetCurrentBasket', getters.getDefaultBasket())
  },

  resetBaskets (context) {
    context.commit('resetBaskets')
  },

  setModalDishesOptions (context, data: ModalDishesOptions) {
    context.commit('setModalDishesOptions', data)
  },

  openModalDish (context, data) {
    context.commit('setModalDishData', data)
  },

  closeModalDish (context) {
    context.commit('resetModalDishData')
  },

  changeNumberOfPersons (context, number: number) {
    context.commit('setNumberOfPersons', number)
  },

  async addProductsInBasket (context, payload) {
    await apiClient.post('/productsInBasket/addProductsInBasket', {
      basket_id: payload.basketId,
      product_id: payload.product.id
    })
    // context.commit('addProductsInBasket', payload.product)
  },

  async removeProductsInBasket ({ commit }, productId: string) {
    const res = await apiClient.post('/productsInBasket/removeProductsInBasket', {
      id: productId
    })
    commit('removeProductsInBasket', productId)
    return res
  },

  async changeNumberOfProductsInBasket ({ commit }, data) {
    try {
      const response = await apiClient.post('/productsInBasket/changeNumberOfProductsInBasket', data)
      const result = response.data.result[0]

      commit('updateProductInBasket', result)
      if (result.basket_id) {
        commit('updateBasketPrice', result.basket_id.price)
      }
      return response
    } catch (error) {
      return { error: error?.response.data }
    }
  },

  /**
   * Проверить используется ли данное блодо в корзинах
   * @param param0 - action param
   * @param dishId - ID проверяемого блюда
   * @returns {Promise} - true если используется и false если не используется нигде
   */
  async checkUsedDishInBasket (context, dishId: string): Promise<IdNameItem> {
    const { data } = await apiClient.get('/basket/getBasketWithDishID', {
      params: {
        dishID: dishId
      }
    })
    return data.payload
  }
}

export default actions
