import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { OrdersState } from '@/store/modules/orders/types'
import main from './main'
import admin from './admin'

export const actions: ActionTree<OrdersState, RootState> = {
  ...main,
  ...admin
}

export default actions
