


































import { mapGetters } from 'vuex'
import BasketCard from '@/components/BasketCard.vue'
import 'swiper/css/swiper.css'
import {
  Swiper, SwiperSlide
} from 'vue-awesome-swiper'
import windowWidth from '@/mixins/windowWidth'
import yaMetrica from '@/mixins/yaMetrica'
import { DEFAULT_CATEGORY_NAME } from '@/common/constants'
import { PropType } from 'vue'
import { Basket } from '@/store/modules/baskets/types'

export default {
  name: 'BasketsCarousel',

  components: {
    Swiper,
    SwiperSlide,
    BasketCard
  },

  mixins: [
    windowWidth,
    yaMetrica
  ],

  props: {
    baskets: {
      type: Array,
      default: () => ([])
    },
    currentCategoryName: {
      type: String as PropType<string>,
      default: () => DEFAULT_CATEGORY_NAME
    }
  },

  data () {
    return {
      slidesCount: 4,
      percentShowingCard: 0.5,
      sliderInitialized: false,
      swiperOptions: {
        speed: 450,
        slidesPerView: 'auto',
        spaceBetween: 15,
        navigation: {
          nextEl: '.slider__nav--next',
          prevEl: '.slider__nav--prev'
        }
      }
    }
  },

  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },

    slideWidth () {
      if (this.sliderInitialized) {
        if (this.windowWidth <= 1740) {
          return (this.$refs.container.clientWidth - 30 - (this.slidesCount * this.swiperOptions.spaceBetween)) / (this.slidesCount + this.percentShowingCard)
        }

        return (this.$refs.container.clientWidth - 30 - this.swiperOptions.spaceBetween * (this.slidesCount - 1)) / this.slidesCount
      }

      return 0
    },

    navWidth () {
      if (!this.sliderInitialized) {
        return 0
      }

      if (this.windowWidth > 1740) {
        return (this.windowWidth - this.$refs.container.clientWidth + 30) / 2
      } else {
        return 120
      }
    },

    basketsVariations () {
      const baskets = this.getBasketsWithDaysVariations()

      return baskets.filter((basket: { isDraft: boolean, basketCategory: string }) => !basket.isDraft && basket.basketCategory === this.currentCategoryName)
    }
  },

  watch: {
    windowWidth (val) {
      if (val <= 440) {
        this.slidesCount = 1
        this.percentShowingCard = 0.05
        this.swiper.params.spaceBetween = 15
      } else if (val <= 576) {
        this.slidesCount = 1
        this.percentShowingCard = 0.05
        this.swiper.params.spaceBetween = 15
        this.swiper.params.speed = 200
      } else if (val <= 940) {
        this.slidesCount = 2
        this.percentShowingCard = 0.05
        this.swiper.params.spaceBetween = 15
      } else if (val <= 1200) {
        this.slidesCount = 3
        this.percentShowingCard = 0.1
        this.swiper.params.spaceBetween = 15
      } else if (val <= 1400) {
        this.percentShowingCard = 0.1
        this.swiper.params.spaceBetween = 15
      } else {
        this.slidesCount = 4
        this.swiper.params.spaceBetween = this.swiperOptions.spaceBetween
      }
    }
  },

  methods: {
    ...mapGetters('baskets', [
      'getBasketsWithDaysVariations'
    ]),

    toBasket (basket: Basket) {
      this.$emit('select-basket')
      const eventName = this.getYMEventName(basket.name)

      this.ymTrack('perehodVkorzinu')
      this.ymTrack(eventName)
      this.$router.push({
        name: 'basket',
        params: { id: basket.id }
      })
    },

    getYMEventName (basketName: string) {
      switch (basketName) {
        case 'Хит продаж':
          return 'HitProdazh'
        case 'Аппетитная':
          return 'Appetizing'
        case 'Легкая':
          return 'Light'
        case 'Студенческая':
          return 'Stud'
        case 'Любимая':
          return 'Loved'
        case 'Оригинальная':
          return 'Original'
        case 'Низкоуглеводная':
          return 'LowCarb'
        case 'Вегетарианская':
          return 'Veg'
        case 'Без глютена':
          return 'GlutLess'
        case 'BOOL’ON':
          return 'BOOL\'ON'
        case 'Летняя':
          return 'Summer'
        case 'Промо-набор':
          return 'promo'
        default:
          break
      }
    }
  }
}
