import { GetterTree } from 'vuex'
import {
  Dish, DishesState, DishesSubCategory, DishProduct, SerializedDish, SerializedDishesSubCategory, SerializedDishProduct
} from './types'
import { RootState } from '@/store/types'
import { valueIdMapItemToBFormSelectOptionsItem } from '@/utils/functions'
import { ValueIdMapItem } from '@/main.types'
import { Product } from '../products/types'

const getters: GetterTree<DishesState, RootState> = {
  getNewDish: () => (): Dish => ({
    name: '',
    isDraft: false,
    calories: 0,
    recipe: '',
    preparationMethods: [],
    image: '',
    foodStyles: [],
    carbohydrates: 0,
    proteins: 0,
    fats: 0,
    id: null,
    preparationTime: 0,
    dishTypes: [],
    products: [],
    videoRecipeURL: '',
    createDate: null,
    minPortions: 2,
    weight: 0,
    isKindergarten: false,
    subCategories: []
  }),

  productToDishProduct: () => (data: Product): DishProduct => ({
    id: null,
    name: data.name,
    globalProductId: data.id,
    quantity: 0,
    isOptional: false,
    altQuantity: '',
    unit: data.unit_id.value,
    categoryName: null
  }),

  serializeDish: () => (data: Dish): SerializedDish => ({
    id: data.id,
    isDraft: data.isDraft,
    preparationTime: data.preparationTime.toString(),
    name: data.name,
    proteins: data.proteins.toString(),
    recipe: data.recipe,
    preparationMethods_ids: data.preparationMethods.map(el => el.value).join(','),
    styles_ids: data.foodStyles.map(el => el.value).join(','),
    image: data.image,
    fats: data.fats.toString(),
    calories: data.calories.toString(),
    carbohydrates: data.carbohydrates.toString(),
    desctiption: data.recipe,
    dishType_id: data.dishTypes.map(el => el.value).join(','),
    dishProducts_ids: data.products?.map(el => el.id).join(','),
    ingredient_ids: data.products?.map(el => el.globalProductId).join(','),
    videoRecipeURL: data.videoRecipeURL,
    minPortions: data.minPortions,
    weight: data.weight,
    dishSubCategory_ids: data.subCategories,
    isKindergarten: data.isKindergarten
  }),

  deserializeDish: () => (data, products?: DishProduct[]): Dish => ({
    id: data.id,
    isDraft: data.isDraft,
    preparationTime: +data.preparationTime,
    name: data.name,
    proteins: +data.proteins,
    recipe: data.recipe,
    preparationMethods: data.preparationMethods_ids.map((el: ValueIdMapItem) => valueIdMapItemToBFormSelectOptionsItem(el)),
    foodStyles: data.styles_ids.map((el: ValueIdMapItem) => valueIdMapItemToBFormSelectOptionsItem(el)),
    image: data.image,
    fats: +data.fats,
    calories: +data.calories,
    carbohydrates: +data.carbohydrates,
    dishTypes: data.dishType_id.map((el: ValueIdMapItem) => valueIdMapItemToBFormSelectOptionsItem(el)),
    products,
    videoRecipeURL: data.videoRecipeURL,
    createDate: data.createDate,
    minPortions: data.minPortions,
    weight: data.weight,
    isKindergarten: data.isKindergarten,
    subCategories: data.dishSubCategory_ids ? data.dishSubCategory_ids : []
  }),

  deserializeDishProduct: () => (data): DishProduct => ({
    id: data.id,
    name: data.product_id?.name,
    globalProductId: data.product_id?.id,
    quantity: data.quantity,
    isOptional: data.isOptional,
    altQuantity: data.altQuantity,
    unit: data.product_id.unit_id?.value,
    categoryName: data.categoryName ?? null
  }),

  serializeDishProduct: () => (data: DishProduct, dishId: string): SerializedDishProduct => ({
    dish_id: dishId,
    id: data.id,
    product_id: data.globalProductId,
    quantity: data.quantity?.toString(),
    isOptional: data.isOptional,
    altQuantity: data.altQuantity,
    categoryName: data.categoryName
  }),

  getNewDishesSubCategory: () => (): DishesSubCategory => ({
    name: '',
    orderIndex: 0,
    dishes_ids: []
  }),

  serializeDishesSubCategory: () => (data: DishesSubCategory) => {
    const obj: SerializedDishesSubCategory = {
      id: data.id,
      name: data.name,
      orderIndex: data.orderIndex,
      image: data.image,
      dishMainCategory_id: data.dishMainCategory_id,
      dishes_ids: data.dishes_ids.map((el: Dish) => el.id)
    }

    // Если прокидывается файл
    if (data.image instanceof File) {
      obj.image_id = obj.image
      delete obj.image
    } else {
      delete obj.image
    }

    return obj
  }
}

export default getters
