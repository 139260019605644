/* eslint-disable camelcase */
import apiClient from '@/services/api/apiClient'
import Vue from 'vue'
import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { NutritionState } from './types'

const itemTypes = { dish: 'Блюдо', drink: 'Напиток', product: 'Продукт' }
const successCheck = (status: string, message: string) => {
  if (status === 'OK') {
    Vue.notify({
      type: 'success',
      text: message
    })
  }
}

export const actions: ActionTree<NutritionState, RootState> = {
  async createNutritionBasket (context) {
    context.commit('common/setLoading', true, { root: true })
    const { data: { result: [{ id }] } } = await apiClient.post('nutritionBasket/editNutritionBasket', {
      id: null,
      user_id: context.rootState.auth?.user?.id || null
    })
    const week = Array(7).fill({ day: '1970-01-01T00:00:00.000Z', nutritionBasket_id: id })
    await apiClient.post('nutritionClientDay/editNutritionClientDay', week)
    await context.dispatch('common/checkRequest', { id: id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })

    return id
  },

  async deleteNutritionBasket (context, id: string) {
    await apiClient.post('nutritionBasket/editNutritionBasket', {
      id,
      isDeleted: true
    })
  },

  async getNutritionBasket (context, id: string) {
    const nutId: string = id || context.state.nutritionBasket.id
    const { data: { payload: [data] } } = await apiClient.get(`/nutritionBasket/getNutritionBasket?nutritionBasketID=${nutId}`)
    const {
      nutritionClientDay_ids: days,
      product_ids: products
    } = data

    context.commit('setNutritionBasketProducts', products?.map(item => context.rootGetters['orders/deserializeOrderProduct'](item)))

    const deserializedNutritionBasket = context.getters.deserializeNutritionBasket(data)
    context.commit('setNutritionBasket', deserializedNutritionBasket)
    const currentDay = days.find(item => item.id === context.state.nutritionCurrentDay.id)
    context.commit('updateNutritionCurrentDay', (currentDay ?? days[0]))

    return deserializedNutritionBasket
  },

  async createDayTime (context) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDayTime/editNutritionDayTime', {
      time: '00:00',
      nutritionClientDay_id: context.state.nutritionCurrentDay.id
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  async updateDayTime (context, [time, id]) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDayTime/editNutritionDayTime', {
      id,
      time,
      nutritionClientDay_id: context.state.nutritionCurrentDay.id
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  async deleteDayTime (context, id: string) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDayTime/editNutritionDayTime', {
      nutritionClientDay_id: context.state.nutritionCurrentDay.id,
      isDeleted: true,
      id
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  async addNutritionDish (context, {
    itemType = '', itemId, minPortions, quantity
  }: {itemType:string, itemId:string, minPortions:number, quantity:number}) {
    if (!context.state.nutritionCurrentDayTimeId) return
    const type:string = itemType === 'product' ? itemType : 'dish'
    context.commit('common/setLoading', true, { root: true })
    const { data: { status } } = await apiClient.post('nutritionDish/editNutritionDish', {
      [`${type}_id`]: itemId,
      newNumberOfPortions: minPortions,
      nutritionDayTime_id: context.state.nutritionCurrentDayTimeId,
      quantity
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })

    const itemName:string = itemTypes[itemType]
    successCheck(status, `${itemName} добавлен${itemType === 'dish' ? 'о' : ''}`)
  },

  async updateNutritionDish (context, [dishID, newNumberOfPortions, nutritionDayTimeID]) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDish/editNutritionDish', {
      id: dishID,
      newNumberOfPortions,
      nutritionDayTime_id: nutritionDayTimeID
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  async updateProductPortionSize (context, [dishID, newQuantity]) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDish/editNutritionDishQuantity', { id: dishID, newQuantity })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  async deleteNutritionDish (context, [dishID, nutritionDayTimeID]) {
    context.commit('common/setLoading', true, { root: true })
    await apiClient.post('nutritionDish/editNutritionDish', {
      id: dishID,
      newNumberOfPortions: 0,
      nutritionDayTime_id: nutritionDayTimeID
    })
    await context.dispatch('common/checkRequest', { id: context.state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    context.commit('common/setLoading', false, { root: true })
  },

  updateNutritionCurrentDay ({ commit }, day) {
    commit('updateNutritionCurrentDay', day)
  },

  updateNutritionCurrentDayTimeId ({ commit }, dayTimeId) {
    commit('updateNutritionCurrentDayTimeId', dayTimeId)
  },

  async updateNutritionProduct ({
    commit,
    dispatch
  }, payload) {
    commit('common/setLoading', true, { root: true })
    const result = await apiClient.post('nutritionProduct/changeNumberOfProductsInNutritionBasket', {
      id: payload.productId,
      quantityPcs: payload.quantityPcs
    })
    const totalSum = result.data?.result[0]?.nutritionBasket_id.totalSum
    const productTotalPrice = result.data?.result[0]?.totalPrice

    if (!totalSum) {
      await dispatch('getNutritionBasket')
      commit('common/setLoading', false, { root: true })
      return
    }
    commit('common/setLoading', false, { root: true })
    commit('updateNutritionBasketTotalSum', totalSum)
    commit('updateNutritionBasketProduct', {
      ...payload,
      totalPrice: productTotalPrice
    })
  },

  async addNutritionProduct ({ dispatch }, payload: {
    nutritionBasketId: string
    productId: string
  }) {
    await apiClient.post('/nutritionProduct/addNutritionProduct', {
      nutritionBasket_id: payload.nutritionBasketId,
      product_id: payload.productId
    })
    await dispatch('getNutritionBasket')
  },

  async changeNutritionShop ({
    state,
    dispatch,
    commit
  }, payload: {
    id: string
    shopId: string
  }) {
    commit('common/setLoading', true, { root: true })
    await apiClient.post('/nutritionBasket/setNutritionBasketShop', {
      id: payload.id,
      selectedShop_id: payload.shopId
    })
    await dispatch('common/checkRequest', { id: state.nutritionBasket.id, type: 'nutrition' }, { root: true })
    commit('common/setLoading', false, { root: true })
  },

  resetNutritionBasket ({ commit }) {
    commit('resetNutritionBasket')
  },

  async replaceProductInNutritionBasket (context, payload: {
    id: string
    originalProduct_id: string
    product_id: string
  }) {
    return await apiClient.post('/nutritionProduct/replaceProductInNutritionBasket', payload)
  }
}

export default actions
