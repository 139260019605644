// Common
export const API_URL = 'https://api.directual.com/good/api/v5/data'
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
export const APP_NAME = process.env.VUE_APP_NAME
export const DOMAIN_URL = process.env.VUE_APP_URL
export const DIRECTUAL_APP_ID = process.env.VUE_APP_DIRECTUAL_APP_ID
export const AIRTABLE_API_KEY = process.env.VUE_APP_AIRTABLE_API_KEY
export const DAYS_IN_WEEK = 7
export const CONTACT_PHONE = '+7(495) 374 79 80'
export const DISH_TYPES = [
  {
    name: 'завтрак',
    slug: 'breakfast',
    id: 1
  },
  {
    name: 'обед',
    slug: 'lunch',
    id: 2
  },
  {
    name: 'ужин',
    slug: 'dinner',
    id: 2
  },
  {
    name: 'перекус',
    slug: 'snack',
    id: 4
  }
]
export const ORDER_PRINT_MENU_COST = 50
export const MIN_DISHES = 2
export const MIN_ORDER_SUM = 1500

// Social
export const INSTAGRAM_LINK = 'https://www.instagram.com/korzina_na_dom/'
export const FACEBOOK_LINK = 'https://www.facebook.com/korzinanadom1/'
export const TELEGRAM_LINK = 'https://t.me/Korzina_nadom'
export const YA_ZEN_LINK = 'https://zen.yandex.ru/id/6178f2a9a956a34ca9fbbee0'

// Payments
export const PAYMENT_API_URL = 'https://securepay.tinkoff.ru/v2/Init'
export const TERMINAL_KEY = process.env.VUE_APP_TINKOFF_TERMINAL_KEY

// Metrics
export const YA_METRICA_ID = process.env.VUE_APP_YA_METRICA_ID

export const PRODUCT_UNITS = [
  {
    id: '3',
    text: 'г.'
  },
  {
    id: '2',
    text: 'мл.'
  },
  {
    id: '1',
    text: 'шт.'
  }
]

export const ROLE_GUEST = 'Guest'
export const ROLE_USER = 'User'
export const ROLE_MANAGER = 'Manager'
export const ROLE_ADMIN = 'Admin'

// Texts
export const CHANGE_BASKET_NOT_AUTH_USER_MSG = 'Изменение корзины возможно через авторизацию'

export default {
  API_URL,
  APP_NAME,
  DOMAIN_URL,
  DIRECTUAL_APP_ID,
  DAYS_IN_WEEK,
  DISH_TYPES,
  ORDER_PRINT_MENU_COST,
  MIN_ORDER_SUM,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  TELEGRAM_LINK,
  PAYMENT_API_URL,
  TERMINAL_KEY,
  YA_METRICA_ID,
  PRODUCT_UNITS,
  ROLE_GUEST,
  ROLE_USER,
  ROLE_ADMIN,
  CHANGE_BASKET_NOT_AUTH_USER_MSG
}

export const DEFAULT_SHOP_ID = 'korzinanadom'

export const AVAILABLE_DELIVERY_REGIONS = [
  'Москва',
  'Московская область',
  'Москва и МО',
  'Санкт-Петербург',
  'Тульская область',
  'Рязанская область',
  'Тверская область',
  'Нижегородская область',
  'Оренбургская область',
  'Челябинская область',
  'Саратовская область',
  'Воронежская область',
  'Белгородская область',
  'Волгоградская область',
  'Пермский край',
  'Мурманская область',
  'Ульяновская область',
  'Ставропольский край',
  'Удмуртская Республика',
  'Краснодарский край',
  'Ростовская область',
  'Тюменская область',
  'Свердловская область',
  'Республика Башкортостан',
  'Чувашская Республика',
  'Самарская область',
  'Республика Татарстан',
  'Псковская область'
]

export const DEFAULT_CATEGORY_NAME = 'от Корзины на дом'

export const RECIPES_COST = 50

export const CONTACT_EMAIL = 'info@korzinanadom.ru'

export const BASKET_CATEGORIES = [
  'от Корзины на дом',
  'от фудблогеров',
  'от нутрициологов и диетологов',
  'от партнеров',
  'от медиа-личностей'
]

export const NUTRITION_DAY_DUMMY = {
  id: null,
  day: 0,
  calories: 0,
  carbohydrates: 0,
  fats: 0,
  nutritionDayTime_ids: [],
  proteins: 0
}
