import { YA_METRICA_ID } from '@/common/constants'

const yaMetrica = {
  methods: {
    ymTrack (eventName: string) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const ym = (window as any).ym

      if (ym) {
        ym(YA_METRICA_ID, 'reachGoal', eventName)
      }
    }
  }
}

export default yaMetrica
