import {
  DishesState,
  DishesSubCategory
} from './types'

const state: DishesState = {
  styleCatalogue: [],
  dishTypeCatalogue: [],
  preparationMethodCatalogue: [],
  dishesCategories: [],
  subCategoryDishes: [],
  subCategory: {} as DishesSubCategory
}

export default state
