import Vue from 'vue'
import {
  BootstrapVue, IconsPlugin
} from 'bootstrap-vue'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import moment from 'moment'
import Notifications from 'vue-notification'
import VSelect from 'vue-select'
import '@/common/globalComponents'
import '@/common/filters'
import App from './App.vue'
import '@/assets/scss/app.scss'
import Clipboard from 'v-clipboard'
import VueCookies from 'vue-cookies'

// import './plugins/errorHandlers'
import './plugins/vee-validate'

Vue.use(Notifications)
Vue.use(Clipboard)

Vue.use(VueCookies)
// set default config
Vue.$cookies.config('7d')

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMoment, { moment })

Vue.component('VSelect', VSelect)

export default new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
