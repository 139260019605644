/* eslint-disable camelcase */
import apiClient from '@/services/api/apiClient'
import { ActionTree } from 'vuex'
import {
  Product, ProductType, ProductsState
} from './types'
import { RootState } from '@/store/types'
import {
  getEntityImagesVariants, objectToFormData
} from '@/utils/functions'

export const actions: ActionTree<ProductsState, RootState> = {
  async getAllProducts ({
    commit,
    getters
  }, params: {
    name: string
    categoryId: string
    shopId: string
    page: number
  }) {
    const res = await apiClient.get('/product/getAllProducts', {
      params: {
        name: params.name,
        category: params.categoryId,
        shop: params.shopId,
        page: params.page
      }
    })

    const deserialized = res.data.payload.map(el => getters.deserializeProduct(el))
    res.data.payload = deserialized

    commit('setProducts', deserialized)

    return res
  },

  async getProductCategories ({ commit }) {
    const res = await apiClient.get('/productCategoryCatalogue/getProductCategoryCatalogue')
    commit('setProductCategories', res.data.payload)
    return res
  },

  async addProductComment (context, data) {
    return await apiClient.post('/productComment/addProductComment', data)
  },

  async createProduct ({
    getters,
    commit
  }, payload: Product) {
    const product = getEntityImagesVariants(payload, getters.serializeProduct.bind(getters))

    delete product.serialized.id
    delete product.serialized.isPackageChanged
    delete product.serialized.isCostChanged

    const formData = objectToFormData(product.serialized)

    const { data } = await apiClient.post('/product/addProduct', formData)

    const newDeserializedProduct = {
      ...product.deserialized,
      id: data.result[0].id,
      initPackage: payload.package,
      initCost: payload.cost
    }

    commit('addProduct', newDeserializedProduct)

    return newDeserializedProduct
  },

  async updateProduct ({
    getters,
    commit
  }, payload: Product) {
    const product = getEntityImagesVariants(payload, getters.serializeProduct.bind(getters))

    const formData = objectToFormData(product.serialized)

    await apiClient.post(
      '/product/editProduct',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    const newDeserializedProduct = {
      ...product.deserialized,
      initCost: payload.cost,
      initPackage: payload.package
    }

    commit('updateProduct', newDeserializedProduct)

    return newDeserializedProduct
  },

  async deleteProduct ({ commit }, payload: Product) {
    await apiClient.post('/product/removeProduct', {
      id: payload.id
    })
    commit('removeProduct', payload.id)
    return payload
  },

  async getProductReplacementCatalogue (
    { commit },
    payload: {
      page: number
      query: string
      pageSize: number
    }
  ) {
    const {
      page = 0,
      query = '',
      pageSize = 20
    } = payload || {}

    const res = await apiClient.get('/productReplacementCatalogue/getProductReplacementCatalogue', {
      params: {
        page,
        query,
        pageSize
      }
    })
    commit('setProductReplacementCatalogue', res.data.payload)
    return res
  },

  async getShopsCatalogue (
    { commit },
    payload: { page: number, pageSize: number }
  ) {
    const {
      page = 0,
      pageSize = 30
    } = payload || {}

    const res = await apiClient.get('/shopsCatalogue/getShopsCatalogue', {
      params: {
        page,
        pageSize
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    commit('setShopsCatalogue', res.data.payload)
    return res
  },

  async editProductReplacementCatalogue ({ commit }, payload: ProductType) {
    const res = await apiClient.post('/productReplacementCatalogue/editProductReplacementCatalogue', payload)
    let commitName = 'createProductType'

    if (payload.id) {
      commitName = 'updateProductType'
    }

    commit(commitName, {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      id: res.data.result[0].id,
      value: payload.value
    })

    return res
  },

  async removeProductReplacementCatalogue ({ commit }, id: string) {
    const res = await apiClient.post('/productReplacementCatalogue/removeProductReplacementCatalogue', id)

    commit('removeProductType', id)

    return res
  },

  async getProductsForReplacement ({ commit }, payload: { productReplacementId: string }) {
    const res = await apiClient.get('/product/getProductForReplacement', {
      params: {
        productReplacementID: payload.productReplacementId
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    commit('setProductsForReplacement', res.data.payload)
    return res
  }
}

export default actions
