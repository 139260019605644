import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Product } from '../products/types'
import {
  Basket, BasketsState, ModalDishesOptions
} from './types'

const mutations: MutationTree<BasketsState> = {
  setBaskets (state, data: Basket[]) {
    state.baskets = data
  },

  resetBaskets (state) {
    state.baskets = []
  },

  setCurrentBasket (state, data: Basket) {
    state.currentBasket = data
  },

  resetCurrentBasket (state, data: Basket) {
    state.currentBasket = data
  },

  setModalDishesOptions (state, data: ModalDishesOptions) {
    state.modalDishesOptions = data
  },

  setModalDishData (state, data) {
    state.modalDishData = data
  },

  resetModalDishData (state) {
    state.modalDishData = {}
  },

  setNumberOfPersons (state, number: number) {
    state.numberOfPersons = number
  },

  setSteps (state, steps) {
    state.steps = steps
  },

  updateProductInBasket (state, data) {
    const productIndex = state.currentBasket.products.findIndex((product: Product) => {
      return product.id === data.id
    })
    const product = state.currentBasket.products[productIndex]

    Vue.set(product, 'totalPrice', data.totalPrice)
    Vue.set(product, 'quantityWeight', data.quantityWeight)
    Vue.set(product, 'quantityPcs', data.quantityPcs)
  },

  removeProductsInBasket (state, productId: string) {
    state.currentBasket.products = state.currentBasket.products.filter((product: Product) => {
      return product.id !== productId
    })
  },

  updateBasketPrice (state, summ: number) {
    state.currentBasket.price = summ
  },

  setKinderPageViewed (state, payload: boolean) {
    state.kinderPageViewed = payload
  }
}

export default mutations
