
















import Vue from 'vue'
import SocialLinks from '@/components/SocialLinks.vue'
import {
  CONTACT_EMAIL,
  CONTACT_PHONE
} from '@/common/constants'

export default Vue.extend({
  name: 'AppFooter',

  components: {
    SocialLinks
  },

  data () {
    return {
      contactEmail: CONTACT_EMAIL,
      contactPhone: CONTACT_PHONE
    }
  }
})
