import {
  NutritionState,
  NutritionBasket
} from './types'
import { NUTRITION_DAY_DUMMY } from '@/common/constants'

const state: NutritionState = {
  nutritionBasket: {
    nutritionClientDays: Array(7).fill({ ...NUTRITION_DAY_DUMMY })
  } as NutritionBasket,
  loading: false,
  nutritionBasketId: null,
  nutritionCurrentDay: { ...NUTRITION_DAY_DUMMY },
  nutritionCurrentDayTimeId: '',
  nutritionBasketProducts: []
}

export default state
