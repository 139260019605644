











import Vue from 'vue'

export default Vue.extend({
  name: 'ConsultantToggle',

  methods: {
    openChat () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).carrotquest.open()
    }
  }
})
