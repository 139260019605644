import {
  ValueIdMapItem,
  BFormSelectOptionItem,
  RouteWithMeta
} from '@/main.types'
import { APP_NAME } from '@/common/constants'

export const valueIdMapItemToBFormSelectOptionsItem = (el: ValueIdMapItem): BFormSelectOptionItem => ({
  text: el.value,
  value: el.id
})

let uniqueIdCounter = 0
export const getUniqueId = (): string => {
  return `${uniqueIdCounter++}${new Date().getTime().toString()}`
}

export const getDishTypeIdByName = (name: string) : number => {
  switch (name) {
    case 'завтрак':
      return 1
    case 'обед':
      return 2
    case 'ужин':
      return 3
    case 'перекус':
      return 4

    default:
      return 1
  }
}

/**
 * Обработка изображений в сущности. Если image файл, то переложить его в image_id, а на место image подставить blobUrl
 * */
export function getEntityImagesVariants<T extends { image?: string | File }> (
  payload: T,
  // eslint-disable-next-line camelcase
  serialize: (payload: T) => { image?: string, image_id?: File }
): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serialized: any
  deserialized: T
} {
  const deserialized = { ...payload }

  const imageIsBlob = typeof deserialized.image === 'string' && deserialized.image.includes('blob')

  let productImageFile = null
  if (deserialized.image instanceof File) {
    productImageFile = deserialized.image
  }

  if (imageIsBlob || productImageFile) {
    delete deserialized.image
  }

  const serialized = serialize(deserialized)

  if (productImageFile) {
    deserialized.image = URL.createObjectURL(productImageFile)
    serialized.image_id = productImageFile
  }

  if (imageIsBlob) {
    deserialized.image = payload.image
    delete serialized.image
  }

  return {
    serialized,
    deserialized
  }
}

export const objectToFormData = (payload: unknown) => {
  const formData = new FormData()

  Object.entries(payload).forEach(([key, val]: [string, string | Blob]) => {
    if (val !== undefined) {
      formData.append(key, val)
    }
  })

  return formData
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const setDocumentTitle = (route: RouteWithMeta) => {
  const appName: string = APP_NAME

  if (route.meta.title) {
    document.title = `${route.meta.title} — ${appName}`
  } else {
    document.title = APP_NAME
  }
}

export const sortArrayByOrderIndex = (array) => {
  return array.slice().sort((a: { orderIndex: number }, b: { orderIndex: number }) => {
    const aSortIndex = a.orderIndex ? a.orderIndex : 0
    const bSortIndex = b.orderIndex ? b.orderIndex : 0
    return (aSortIndex > bSortIndex) ? 1 : -1
  })
}

export const formatPrice = (value: number) => {
  if (!value) return value
  return value.toLocaleString('ru', {
    maximumFractionDigits: 0
  })
}

export default {
  valueIdMapItemToBFormSelectOptionsItem,
  getUniqueId,
  setDocumentTitle
}
