import Vue from 'vue'
import { MutationTree } from 'vuex'
import {
  OrderProduct,
  OrdersState
} from '@/store/modules/orders/types'

const mutations: MutationTree<OrdersState> = {
  setNumberOfPersonsInOrder (state, amount: number) {
    Vue.set(state.currentOrder, 'numberOfPersons', amount)
  },

  setCurrentOrder (state, data) {
    state.currentOrder = data
  },

  setCurrentOrderProducts (state, products: OrderProduct[]) {
    Vue.set(state.currentOrder, 'products', products)
  },

  setCurrentOrderDishes (state, data) {
    Vue.set(state.currentOrder, 'dishes', data)
  },

  removeDishInOrder (state, dishId: string) {
    state.currentOrder.dishes = state.currentOrder.dishes.filter((item: { id: string }) => {
      return item.id !== dishId
    })
  },

  updateTotalSumm (state, summ: number) {
    Vue.set(state.currentOrder, 'totalSum', summ)
  },

  updateCurrentOrderStatus (state, statusId: string) {
    if (!state.currentOrder.status) {
      state.currentOrder.status = {
        id: statusId
      }
    } else {
      Vue.set(state.currentOrder.status, 'id', statusId)
    }
  },

  updateCurrentOrderPayStatus (state, status: boolean) {
    Vue.set(state.currentOrder, 'pay_status_id', status)
  },

  setClientOrders (state, orders) {
    state.orders = orders
  },

  setDraftOrdersCount (state, count: number) {
    state.draftOrdersCount = count
  },

  resetCurrentOrder (state) {
    state.currentOrder = {
      id: null,
      status: {
        id: null,
        value: null
      },
      basket: {
        id: null,
        name: null,
        isKindergarten: null
      },
      orderNumber: null,
      orderDate: null,
      isRecipe: false,
      isClientPaid: false,
      totalSum: null,
      numberOfPersons: null,
      basketName: null,
      orderAddress: null,
      dishes: null,
      products: null,
      paymentMethod: null,
      selectedShop: {
        id: null,
        value: null
      },
      bonusesUsed: 0
    }
  },

  setSaveOrderAfterAuth (state, orderId: string) {
    state.saveOrderAfterAuth = orderId
  },

  setRedirectUrlAfterAuth (state, url: string) {
    state.redirectUrlAfterAuth = url
  },

  removeProductsInOrder (state, productId: string) {
    state.currentOrder.products = state.currentOrder.products.filter(item => {
      return item.id !== productId
    })
  },

  updateProductAmountInOrder (state, data) {
    const productIndex = state.currentOrder.products.findIndex(item => {
      return item.id === data.id
    })

    Vue.set(state.currentOrder.products[productIndex], 'quantityPcs', Number(data.quantityPcs))
  },

  addProductsInOrder (state, product) {
    state.currentOrder.products.push(product)
  },

  setDishDetailProducts (state, data) {
    state.dishDetailProducts = data
  },

  setClientOrder (state, data) {
    state.clientOrder = data
  },

  setCheckoutFormValidation (state, val: boolean) {
    state.checkoutFormIsValid = val
  },

  addProductInOrder (state, data) {
    state.currentOrder.products.push(data)
    Vue.set(state.currentOrder, 'totalSum', data.totalSum)
  }
}

export default mutations
