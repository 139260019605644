





















































import Vue, { PropType } from 'vue'
import SocialLinks from '@/components/SocialLinks.vue'
import { NavigationItem } from '@/main.types'
import {
  CONTACT_PHONE,
  CONTACT_EMAIL
} from '@/common/constants'

export default Vue.extend({
  name: 'MobileMenu',

  components: {
    SocialLinks
  },

  props: {
    navItems: {
      type: Array as PropType<NavigationItem[]>,
      default: () => ([] as NavigationItem[])
    }
  },

  data () {
    return {
      mobileMenuVisible: false,
      contactEmail: CONTACT_EMAIL,
      contactPhone: CONTACT_PHONE
    }
  },

  watch: {
    mobileMenuVisible (val) {
      this.toggleBodyClass(val)
    },

    $route () {
      this.mobileMenuVisible = false
    }
  },

  methods: {
    onMobileLink (link: NavigationItem) {
      if (this.$route.name === link.name) {
        const basketsEl = document.querySelector<HTMLElement>('#baskets')
        window.scrollTo(0, basketsEl.offsetTop - 30)
      }
      this.mobileMenuVisible = false
    },

    openChat () {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).carrotquest.open()
    },

    toggleBodyClass (visible: boolean) {
      const className = 'mobile-menu-open'
      const bodyElement = document.body

      visible ? bodyElement.classList.add(className) : bodyElement.classList.remove(className)
    }
  }
})
