import { ProductsState } from '@/store/modules/products/types'

const state: ProductsState = {
  products: [],
  productCategories: [],
  replacingProduct: null,
  modalRemovingProduct: {},
  productReplacementCatalogue: [],
  productsForReplacement: [],
  shopsCatalogue: [],
  productsView: localStorage.getItem('products-view') || 'table'
}

export default state
