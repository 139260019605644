



















import Vue from 'vue'
import { mapState } from 'vuex'
import AuthForm from '@/components/AuthForm.vue'

export default Vue.extend({
  name: 'ModalLogin',

  components: {
    AuthForm
  },

  data () {
    return {
      isInputFocused: false
    }
  },

  computed: {
    ...mapState('auth', [
      'authText'
    ])
  },

  methods: {
    onModalShown () {
      this.isInputFocused = true
    },

    onModalHidden () {
      this.isInputFocused = false
    }
  }
})
