import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AuthState } from './types'

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated (state) {
    return state.isAuthenticated
  }
}

export default getters
