













import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'SvgIcon',

  props: {
    name: {
      type: String as PropType<string>,
      required: true
    },

    iconSize: {
      type: String,
      default: ''
    }
  },

  computed: {
    iconPath () {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      let icon = require(`@/assets/icons/${this.name}.svg`)

      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return icon
    },

    className () {
      return 'svg-icon svg-icon--' + this.name
    },

    style () {
      return { width: this.iconSize, height: this.iconSize }
    }
  }
})
