import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import { Module } from 'vuex'
import { AuthState } from './types'
import { RootState } from '@/store/types'

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default auth
