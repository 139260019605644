import { MutationTree } from 'vuex'
import {
  DishesState,
  DishesCategory,
  DishesSubCategory
} from './types'
import { ValueIdMapItem } from '@/main.types'
import { valueIdMapItemToBFormSelectOptionsItem } from '@/utils/functions'

const mutations: MutationTree<DishesState> = {
  setStyleCatalogue (state, data: ValueIdMapItem[]) {
    state.styleCatalogue = data.map(el => valueIdMapItemToBFormSelectOptionsItem(el))
  },

  setDishTypeCatalogue (state, data: ValueIdMapItem[]) {
    state.dishTypeCatalogue = data.map(el => valueIdMapItemToBFormSelectOptionsItem(el))
  },

  setPreparationMethodCatalogue (state, data: ValueIdMapItem[]) {
    state.preparationMethodCatalogue = data.map(el => valueIdMapItemToBFormSelectOptionsItem(el))
  },

  setDishesCategories (state, data: DishesCategory[]) {
    state.dishesCategories = data
  },

  setSubCategory (state, data: DishesSubCategory) {
    state.subCategory = data
  }
}

export default mutations
