
































































import Vue from 'vue'
import {
  mapMutations,
  mapGetters
} from 'vuex'
import ClickOutside from 'vue-click-outside'
import { loadYmap } from 'vue-yandex-maps'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const ymaps: any

export default Vue.extend({
  name: 'SelectRegion',

  directives: {
    ClickOutside
  },

  data () {
    return {
      regions: [
        // Города федерального значения
        'Москва и МО',
        'Санкт-Петербург',

        // Области
        'Амурская область',
        'Архангельская область',
        'Астраханская область',
        'Белгородская область',
        'Брянская область',
        'Владимирская область',
        'Волгоградская область',
        'Вологодская область',
        'Воронежская область',
        'Ивановская область',
        'Иркутская область',
        'Калининградская область',
        'Калужская область',
        'Кемеровская область — Кузбасс',
        'Кировская область',
        'Костромская область',
        'Курганская область',
        'Курская область',
        'Ленинградская область',
        'Липецкая область',
        'Магаданская область',
        // 'Московская область',
        'Мурманская область',
        'Нижегородская область',
        'Новгородская область',
        'Новосибирская область',
        'Омская область',
        'Оренбургская область',
        'Орловская область',
        'Пензенская область',
        'Псковская область',
        'Ростовская область',
        'Рязанская область',
        'Самарская область',
        'Саратовская область',
        'Сахалинская область',
        'Свердловская область',
        'Смоленская область',
        'Тамбовская область',
        'Тверская область',
        'Томская область',
        'Тульская область',
        'Тюменская область',
        'Ульяновская область',
        'Челябинская область',
        'Ярославская область',

        // Республики
        'Республика Адыгея',
        'Республика Алтай',
        'Республика Башкортостан',
        'Республика Бурятия',
        'Республика Дагестан',
        'Республика Ингушетия',
        'Кабардино-Балкарская Республика',
        'Республика Калмыкия',
        'Карачаево-Черкесская Республика',
        'Республика Карелия',
        'Республика Коми',
        'Республика Крым',
        'Республика Марий Эл',
        'Республика Мордовия',
        'Республика Саха (Якутия)',
        'Республика Северная Осетия',
        'Республика Татарстан',
        'Республика Тыва',
        'Удмуртская Республика',
        'Республика Хакасия',
        'Чеченская Республика',
        'Чувашская Республика',

        // Края
        'Алтайский край',
        'Забайкальский край',
        'Камчатский край',
        'Краснодарский край',
        'Красноярский край',
        'Пермский край',
        'Приморский край',
        'Ставропольский край',
        'Хабаровский край',

        // Автономная область
        'Еврейская АО',

        // Автономные округа
        'Ненецкий АО',
        'Ханты-Мансийский АО — Югра',
        'Чукотский АО',
        'Ямало-Ненецкий АО'
      ],
      settings: {
        apiKey: '6020a9f5-ddec-4059-a992-9fda75aa46b7',
        lang: 'ru_RU',
        coordorder: 'latlong',
        version: '2.1'
      },
      toggleHtmlClass: 'is-select-region-popup-opened',
      searchValue: '',
      currentRegion: localStorage.getItem('region') ? localStorage.getItem('region') : null,
      currentCity: localStorage.getItem('city') ? localStorage.getItem('city') : null,
      isOpenList: false,
      ymapsLoaded: false,
      popupShowed: false,
      highlighted: false
    }
  },

  computed: {
    ...mapGetters('common', [
      'deliveryAvailable'
    ]),

    region () {
      return this.currentRegion ?? 'Выбрать регион'
    },

    filteredCities () {
      const search = this.searchValue.toLowerCase().trim()

      if (!search) return this.regions

      return this.regions.filter(item => item.toLowerCase().indexOf(this.searchValue.toLowerCase()) > -1)
    }
  },

  watch: {
    isOpenList (val) {
      if (val) {
        setTimeout(() => {
          this.focusSearchInput()
        }, 100)
        return
      }

      setTimeout(() => {
        this.searchValue = ''
      }, 250)
    }
  },

  async mounted () {
    this.resetOldLogic()
    // this.checkDeliveryAvailableParam()

    if (this.currentRegion) {
      return
    }

    await loadYmap({
      ...this.settings,
      debug: true
    })

    this.ymapsLoaded = true

    // eslint-disable-next-line no-undef
    ymaps.geolocation.get({
      // Зададим способ определения геолокации
      // на основе ip пользователя.
      provider: 'yandex',
      // Включим автоматическое геокодирование результата.
      autoReverseGeocode: true
    }).then((result) => {
      const res = result.geoObjects.get(0).properties.get('metaDataProperty')
      const AdministrativeAreaName = res.GeocoderMetaData.AddressDetails.Country.AdministrativeArea.AdministrativeAreaName
      const components = res.GeocoderMetaData.Address.Components

      this.currentRegion = AdministrativeAreaName

      for (let i = 0; i < components.length; i++) {
        const item = components[i]

        if (item.kind === 'locality') {
          this.currentCity = item.name
          this.handleSaveRegion()
          this.showPopup()
        }
      }
    }).catch(() => {
      this.openList()
    })
  },

  methods: {
    ...mapMutations('common', [
      'setRegion',
      'setCity'
    ]),

    resetOldLogic () {
      const param = localStorage.getItem('is-reset-select-region-logic-v2')

      if (param) return

      localStorage.removeItem('region')
      localStorage.removeItem('city')
      localStorage.setItem('is-reset-select-region-logic-v2', '1')
    },

    highlightlightPopup () {
      this.highlighted = true

      setTimeout(() => {
        this.highlighted = false
      }, 400)
    },

    focusSearchInput () {
      this.$refs['search-input'].$el.focus()
    },

    handleSelectRegion (cityName: string) {
      this.currentRegion = cityName
      this.setRegion(this.currentRegion)
      this.setCity(null)
      this.closeList()
      // this.checkAvailableDelivery()
    },

    handleSaveRegion () {
      this.setRegion(this.currentRegion)
      this.setCity(this.currentCity)
      this.closePopup()
      localStorage.setItem('region', this.currentRegion)
      localStorage.setItem('city', this.currentCity)
    },

    getSavedRegion () {
      const region = JSON.parse(localStorage.getItem('region'))

      if (region) return region
    },

    closePopup () {
      this.popupShowed = false
      document.querySelector('html').classList.remove(this.toggleHtmlClass)
    },

    showPopup () {
      this.popupShowed = true
      document.querySelector('html').classList.add(this.toggleHtmlClass)
    },

    openList () {
      this.isOpenList = true
    },

    closeList () {
      this.isOpenList = false
    },

    clickOutside () {
      if (!this.isOpenList) return
      this.closeList()
      this.closePopup()
    }
  }
})
