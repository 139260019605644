import Vue from 'vue'

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'

extend('phone', {
  message: (field) => 'Поле ' + field + ' обязательно для заполнения',
  validate: value => {
    return value.replace(/\D/g, '').length === 11
  }
})

extend('decimal', {
  validate: (value, {
    decimals = '*',
    separator = '.'
  } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value)
      }
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)

    return {
      valid: regex.test(value)
    }
  },
  message: 'Поле {_field_} должно содержать только десятичные значения'
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('ru', ru)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
