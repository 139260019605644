import { Route } from 'vue-router'

export default {
  setCommonStateValue (state, [name, value]) {
    state[name] = value
  },

  setRegion (state, regionName: string) {
    state.savedRegion = regionName
    localStorage.setItem('region', regionName)
  },

  setCity (state, cityName: string | null) {
    state.savedCity = cityName

    if (!cityName) {
      localStorage.removeItem('city')
      return
    }

    localStorage.setItem('city', cityName)
  },

  setFoodStyles (state, data: string) {
    state.foodStyles = data
    localStorage.setItem('foodStyles', data)
  },

  toggleNewHomePage (state, val: boolean) {
    state.newHomePage = val

    if (val) {
      localStorage.setItem('newHomePage', 'true')
    } else {
      localStorage.removeItem('newHomePage')
    }
  },

  setHomePageOfferId (state, data: string) {
    state.homePageOfferId = data
    localStorage.setItem('homePageOfferId', data)
  },

  setRoute (state, route: Route) {
    state.route = route
  },

  setLoading (state, value: boolean) {
    state.loading = value
  }
}
