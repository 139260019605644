import {
  BasketsState, Basket
} from './types'

const state: BasketsState = {
  baskets: [],
  kinderPageViewed: false,
  currentBasket: {
    dishes: [],
    products: []
  } as Basket,
  selectedDayIndex: 0,
  selectedDishType: '',
  numberOfPersons: 2,
  dishDetail: {},
  modalDishesOptions: {
    typeName: null,
    dayIndex: null,
    isKindergarten: null
  },
  modalDishData: {
    day_id: {
      value: ''
    },
    dish_id: {
      name: '',
      calories: 0,
      recipe: '',
      preparationMethods_ids: [
        {
          value: '',
          id: ''
        }
      ],
      image: '',
      styles_ids: [
        {
          value: '',
          id: ''
        },
        {
          value: '',
          id: ''
        },
        {
          value: '',
          id: ''
        },
        {
          value: '',
          id: ''
        }
      ],
      carbohydrates: 0,
      proteins: 0,
      fats: 0,
      preparationTime: 0,
      minPortions: 2
    },
    type_id: {
      value: ''
    },
    id: ''
  },
  steps: [
    {
      name: '',
      id: 'dishes'
    },
    {
      name: 'Продукты',
      id: 'products'
    },
    {
      name: 'Оформление заказа',
      id: 'checkout'
    }
  ]
}

export default state
