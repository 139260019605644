














import Vue from 'vue'
import {
  TELEGRAM_LINK,
  YA_ZEN_LINK
} from '@/common/constants'

export default Vue.extend({
  name: 'SocialLinks',

  data () {
    return {
      data: ''
    }
  },

  computed: {
    // instagramLink () {
    //   return INSTAGRAM_LINK
    // },
    // facebookLink () {
    //   return FACEBOOK_LINK
    // },
    telegramLink () {
      return TELEGRAM_LINK
    },
    yaZenLink () {
      return YA_ZEN_LINK
    }
  }
})
