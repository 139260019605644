



















































































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'

import InputMask from '@/components/InputMask.vue'
import ApiClient from '@/services/api/apiClient'
import facebookPixel from '@/mixins/facebookPixel'

export default Vue.extend({
  name: 'AuthForm',

  components: {
    InputMask
  },

  mixins: [
    facebookPixel
  ],

  props: {
    isInputFocused: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },

    isHeaderCentered: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },

    title: {
      type: String as PropType<string>,
      default: () => null
    },

    text: {
      type: String as PropType<string>,
      default: () => null
    }
  },

  data () {
    return {
      phone: '',
      code: '',
      formDisabled: false,
      verficationCodeSent: false,
      sendCodeResponse: '',
      verificationCodeResponse: '',
      repeatSendTime: 60,
      remainTime: 0,
      formVerificationCodeDisabled: false
    }
  },

  computed: {
    ...mapState('orders', [
      'saveOrderAfterAuth',
      'redirectUrlAfterAuth'
    ]),

    phoneValid (): boolean {
      return this.phone.length === 11
    },

    verificateCodeValid (): boolean {
      return this.code.length === 4
    }
  },

  watch: {
    verficationCodeSent (val) {
      if (val) {
        this.remainTime = this.repeatSendTime
        this.countDown()
      }
    },

    isInputFocused (val: boolean) {
      if (val) this.focusInput('input')
    }
  },

  methods: {
    ...mapActions('auth', [
      'login',
      'getUser',
      'verificateCode',
      'resetAuthText'
    ]),

    ...mapActions('orders', [
      'getClientOrdersDrafts',
      'changeOrderStatus'
    ]),

    ...mapMutations('orders', [
      'setRedirectUrlAfterAuth'
    ]),

    async sendCode () {
      this.verificationCodeResponse = ''
      this.formDisabled = true

      try {
        const res = await ApiClient.post('/WebUser/authorizeUser', { id: this.phone })

        if (res.data.result[0].isVerficationCodeSent) {
          this.verficationCodeSent = true
          this.sendCodeResponse = ''
          setTimeout(() => {
            this.focusInput('codeInput')
          }, 0)
        } else {
          this.sendCodeResponse = 'Код доступа не был отправлен. Попробуйте позже.'
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.formDisabled = false
      }
    },

    async checkVerificationCode () {
      try {
        this.formVerificationCodeDisabled = true
        const res = await this.verificateCode({
          id: this.phone,
          inputVerificationCode: this.code
        })

        if (!res.data.result[0].isVerified) {
          this.verificationCodeResponse = 'Неправильный код доступа'
          setTimeout(() => {
            this.focusInput('codeInput')
          }, 0)
          return
        }

        await this.login({
          id: this.phone,
          sessionId: res.data.result[0].userSession
        })
        this.fbqTrack('CompleteRegistration')
        this.verficationCodeSent = false
        this.verificationCodeResponse = ''
        this.$bvModal.hide('modal-login')

        await this.getUser()

        await this.getClientOrdersDrafts(this.phone)

        if (this.saveOrderAfterAuth) {
          await this.changeOrderStatus({
            orderId: this.saveOrderAfterAuth,
            statusId: 6
          })
        }

        if (this.redirectUrlAfterAuth) {
          this.$router.push(this.redirectUrlAfterAuth)
          this.setRedirectUrlAfterAuth(null)
        }
      } catch (error) {
        console.warn(JSON.stringify(error))
      } finally {
        this.formVerificationCodeDisabled = false
        this.code = ''
      }
    },

    changeNumber () {
      this.verficationCodeSent = false
      setTimeout(() => {
        this.focusInput('input')
      }, 0)
    },

    focusInput (refName: string) {
      if (this.$refs[refName]) {
        const input = ((this.$refs[refName] as Vue).$el as HTMLElement)

        if (input instanceof HTMLElement) {
          input.focus()
        }
      }
    },

    countDown () {
      if (this.remainTime) {
        return setTimeout(() => {
          --this.remainTime
          this.countDown()
        }, 1000)
      }
    },

    reset () {
      this.resetAuthText()
      this.sendCodeResponse = ''
      this.code = ''
      this.phone = ''
      this.verificationCodeResponse = ''
    }
  }
})
