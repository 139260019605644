import * as authService from '@/services/auth'

export default (to, from, next) => {
  const currentRole = authService.getRole()

  if (!currentRole) {
    authService.logout()
    this.$notify({
      type: 'error',
      text: 'У пользователя нет роли'
    })
    return next('/auth')
  }

  /* Если маршрут вложенный и у него нет своих ограничений по ролям,
  *  то взять ограничения у родительского маршрута */
  let roles = to.meta?.roles

  if (!roles) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const route = to.matched[i]
      if (!route.meta?.roles) continue
      roles = route.meta.roles
      break
    }
  }

  if (!roles.includes(currentRole)) {
    return next('/')
  }

  return next()
}
