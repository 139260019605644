import apiClient from '@/services/api/apiClient'
import { sleep } from '@/utils/functions'
import Vue from 'vue'

let requestCounter = 0

export default {
  setCommonStateValue (context, [name, value]) {
    context.commit('setCommonStateValue', [name, value])
  },

  async getOrderCounter (context, id: string) {
    const res = await apiClient.get('/order/getOrderCounter', {
      params: {
        orderID: id
      }
    })
    return res
  },

  async getBasketCounter (context, id: string) {
    const res = await apiClient.get('/basket/getBasketCounter', {
      params: {
        basketID: id
      }
    })
    return res
  },

  async getNutritionBasketCounter (context, id: string) {
    const res = await apiClient.get('/nutritionBasket/getNutritionBasketCounter', {
      params: {
        nutritionBasketID: id
      }
    })
    return res
  },

  async getRecipeShopsCatalogue () {
    const res = await apiClient.get('/recipeShopsCatalogue/getRecipeShopsCatalogue', {
      params: {
        pageSize: 1000
      }
    })
    return res
  },

  async checkRequest (context, payload: {
    id: string
    type: string
  }) {
    const maxSyncRequestTime = 25

    if (requestCounter === maxSyncRequestTime) {
      requestCounter = 0

      Vue.notify({
        type: 'warn',
        text: 'Произошла ошибка на сервере. Обновите страницу и попробуйте ещё раз позже.'
      })
      return Promise.resolve(new Error('слишком долгий запрос'))
    }

    let res = null

    switch (payload.type) {
      case 'basket':
        res = await context.dispatch('getBasketCounter', payload.id)
        break
      case 'order':
        res = await context.dispatch('getOrderCounter', payload.id)
        break
      case 'nutrition':
        res = await context.dispatch('getNutritionBasketCounter', payload.id)
        break
      default:
        break
    }

    if (res.data?.payload[0]?.currentCount === res.data?.payload[0]?.totalCount) {
      requestCounter = 0

      switch (payload.type) {
        case 'basket':
          res = await context.dispatch('baskets/getBasket', payload.id, { root: true })
          break
        case 'order':
          res = await context.dispatch('orders/getOrder', payload.id, { root: true })
          break
        case 'nutrition':
          res = await context.dispatch('nutrition/getNutritionBasket', payload.id, { root: true })
          break
        default:
          break
      }

      return true
    } else {
      await sleep(500)
      requestCounter += 1
      await context.dispatch('checkRequest', payload)
    }
  }
}
