const facebookPixel = {
  methods: {
    fbqTrack (eventName: string, params = null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fbq = (window as any).fbq

      if (fbq) {
        fbq('track', eventName, params)
      }
    }
  }
}

export default facebookPixel
