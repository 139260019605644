/* eslint-disable camelcase */
import apiClient from '@/services/api/apiClient'
import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import {
  Order,
  OrderProduct,
  OrdersState,
  SerializedOrder
} from '@/store/modules/orders/types'
import { PageInfo } from '@/main.types'

export const actions: ActionTree<OrdersState, RootState> = {
  async changeNumberOfPersonsInOrder ({ commit }, data: {
    id: string
    numberOfPersons: number
  }) {
    try {
      const res = await apiClient.post('/order/changeNumberOfPersonsInOrder', data)
      commit('setNumberOfPersonsInOrder', data.numberOfPersons)
      commit('updateTotalSumm', res.data.result[0].totalSum)
      return res
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async deleteOrder (context, id: string) {
    return await apiClient.post('/order/updateOrder', {
      id,
      isDeleted: true
    })
  },

  async setOrderNumberOfPortions (context, payload: {
    id: string
    numberOfPersons: number
  }) {
    try {
      return await apiClient.post('/order/setOrderNumberOfPortions', payload)
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async createOrder (context, payload) {
    const request = await apiClient.post('/order/addOrder', payload)

    if (request.status >= 200 && request.status < 300) {
      context.commit('setCurrentOrder', request.data.result[0])
      context.commit('setDraftOrdersCount', Number(context.state.draftOrdersCount) + 1)
      context.commit('baskets/setCurrentBasket', {}, { root: true })
    }
    return request
  },

  async changeOrderStatus ({
    commit,
    rootState
  }, payload: {
    orderId: string
    userId: string
    statusId: string
  }) {
    const data = {
      id: payload.orderId || (rootState.nutrition.nutritionBasket.id ? rootState.nutrition.nutritionBasket.orderId : rootState.orders.currentOrder.id),
      user_id: payload.userId || rootState.auth.user.id,
      status_id: payload.statusId.toString()
    }
    try {
      const res = await apiClient.post('/order/changeOrderStatus', data)
      commit('updateCurrentOrderStatus', payload.statusId)
      return res
    } catch (error) {
      console.warn(error)
      return { error: error.response.data }
    }
  },

  async changeOrderPayStatus (context, data: {
    id: string
    isClientPaid: boolean
  }) {
    try {
      return await apiClient.post('/order/changeOrderPayStatus', data)
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async getOrderProducts ({
    commit,
    getters
  }, orderId: string) {
    try {
      const res = await apiClient.get(`/order/getOrderProducts?orderID=${orderId}`)
      const deserializedProducts = res.data.payload[0].products_ids?.map((product: OrderProduct) => getters.deserializeOrderProduct(product)) ?? []

      commit('setCurrentOrderProducts', deserializedProducts)
      commit('updateTotalSumm', res.data.payload[0].totalSum)
      return res
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async getOrderDishes ({ commit }, orderId: string) {
    try {
      const res = await apiClient.get(`/order/getOrderDishes?orderID=${orderId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      commit('setCurrentOrderDishes', res.data.payload[0].dishes_ids)
      return res
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async getClientOrders (
    {
      commit,
      getters
    },
    payload: number | {
      userID: string
      deserialize: boolean
      page: number
    }
  ) {
    const {
      userID,
      deserialize = false,
      page = 0
    } = typeof payload === 'number' ? { userID: payload } : payload
    const res = await apiClient.get('/order/getClientOrders', {
      params: {
        userID,
        page
      }
    })

    if (!deserialize) {
      commit('setClientOrders', res.data.payload)
      return res
    }

    const deserializedOrders = res.data.payload.map((el: SerializedOrder) => getters.deserializeOrder(el) as Order) as Order[]
    commit('setClientOrders', deserializedOrders)
    return {
      pageInfo: res.data.pageInfo as PageInfo,
      payload: deserializedOrders
    }
  },

  async getClientOrdersDrafts ({ commit }, userID: number) {
    try {
      const res = await apiClient.get(`/order/getClientOrdersDrafts?userID=${userID}`, {
        params: {
          pageSize: 100
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      commit('setDraftOrdersCount', res.data.payload.length)
      return res
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async removeDishInOrder ({ commit }, dishId: string) {
    const res = await apiClient.post('/dishInOrder/removeDishInOrder', { id: dishId })
    commit('removeDishInOrder', dishId)
    commit('updateTotalSumm', res.data.result[0].order_id.totalSum)
    return res
  },

  async addDishInOrder (context, data: {
    day_id: string
    dish_id: string
    mealType_id: number
    order_id: string
  }) {
    try {
      return await apiClient.post('/dishInOrder/addDishInOrder', data)
    } catch (error) {
      return { error: error as Error }
    }
  },

  resetCurrentOrder (context) {
    context.commit('resetCurrentOrder')
  },

  async removeProductsInOrder ({ commit }, productId: string) {
    try {
      const res = await apiClient.post('/productsInOrder/removeProductsInOrder', { id: productId })

      commit('removeProductsInOrder', productId)
      return res
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async changeNumberOfProductsInOrder ({ commit }, data: {
    id: string
    quantityPcs: string
  }) {
    try {
      const response = await apiClient.post('/productsInOrder/changeNumberOfProductsInOrder', data)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const result = response.data.result[0] as {
        id: string
        order_id: {
          totalSum: number
        }
        package: number
        price: number
        quantityPcs: number
        quantityWeight: number
        totalPrice: number
        weightPerOnePerson: number
      }

      commit('updateProductAmountInOrder', data)

      if (result.order_id) {
        commit('updateTotalSumm', result.order_id.totalSum)
      }
      return response
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  // async addProductsInOrder ({ commit }, payload) {
  //   try {
  //     return await apiClient.post('/productsInOrder/addProductsInOrder', { basket_id: payload.basket_id, product_id: payload.product.id })
  //   } catch (error) {
  //     return { error: error.response.data }
  //   }
  // },

  async getDishInProducts ({ commit }, dishId: number) {
    try {
      const res = await apiClient.get(`/dish/getDishInProducts?dishID=${dishId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (res.data.payload.length) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        commit('setDishDetailProducts', res.data.payload[0].dishProducts_ids)
      }
      return res
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async getDishInBasketProducts ({ commit }, dishId: number) {
    try {
      const res = await apiClient.get(`/dishesInBasket/getDishInBasketProducts?dishID=${dishId}`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (res.data.payload.length) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        commit('setDishDetailProducts', res.data.payload[0].dish_id.dishProducts_ids)
      }
      return res
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async getOrder (
    {
      commit,
      getters
    },
    payload: string | { id: string, deserialize: boolean }
  ) {
    const orderId = typeof payload === 'string' ? payload : payload.id
    const deserialize = typeof payload === 'string' ? false : payload.deserialize

    const res = await apiClient.get(`/order/getOrder?orderID=${orderId}`)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!res.data.payload?.length) {
      const error = new Error('Заказ не найден') as Error & { code?: number }
      error.code = 404
      throw error
    }

    const deserializedOrder = getters.deserializeOrder(res.data.payload[0]) as Order
    commit('setNumberOfPersonsInOrder', deserializedOrder.numberOfPersons)
    commit('setCurrentOrder', deserializedOrder)

    if (!deserialize) return res

    return getters.deserializeOrder(res.data.payload[0]) as Order
  },

  async getClientOrder (
    {
      commit,
      getters
    },
    payload: string | { orderId: string, deserialize: boolean }
  ) {
    const orderID = typeof payload === 'string' ? payload : payload.orderId
    const deserialize = typeof payload === 'string' ? false : payload.deserialize
    const res = await apiClient.get('/order/getClientOrderById', {
      params: {
        orderID
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (res.data.payload.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      commit('setClientOrder', res.data.payload[0])
    }

    if (!deserialize) return res

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    return getters.deserializeOrder(res.data.payload[0])
  },

  async addProductInOrder (context, payload: {
    product_id: string
    order_id: string
  }) {
    try {
      return await apiClient.post('/productsInOrder/addProductsInOrder', {
        order_id: payload.order_id,
        product_id: payload.product_id
      })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  async replaceProductInOrder (context, payload: {
    id: string
    originalProduct_id: string
    product_id: string
  }) {
    return await apiClient.post('/productsInOrder/replaceProductInOrder', payload)
  },

  // Поменять магазин продуктов
  async setOrderShop (context, payload: {
    id: string // id заказа
    selectedShopId: string // магазин (metro или vkusvill). Лучше брать из справочника'
  }) {
    try {
      return await apiClient.post('/order/setOrderShop', {
        id: payload.id,
        selectedShop_id: payload.selectedShopId
      })
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  },

  // Проверить промокод
  async validatePromocode ({ rootState }, promocodeId: string) {
    try {
      const res = await apiClient.post('/validatepromocode/validatePromocode', {
        promocode: promocodeId,
        user_id: rootState.auth.user.id
      })
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return res.data.result[0] as {
        isValid: boolean
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return { error: error.response.data }
    }
  }
}

export default actions
