import { ROLE_GUEST } from '@/common/constants'
import * as authService from '@/services/auth'
import { EventBus } from '@/EventBus'

export default (to, from, next) => {
  const currentRole = authService.getRole()

  if ((to.name === 'order-checkout' || to.name === 'nutrition-checkout') && currentRole === ROLE_GUEST) {
    if (!from.name) return next('/')

    EventBus.$emit('try-checkout-order', to)
    return next(false)
  }

  return next()
}
