import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export interface PaymentInitResponse {
  TerminalKey: string
  Amount: number
  OrderId: string
  Success: boolean
  Status: string
  PaymentId: number
  ErrorCode: string
  PaymentURL: string
  Message: string
  Details: string
}
export interface PaymentItems {
  Name: string
  Quantity: number
  Amount: number
  Price: number
  PaymentMethod?: string
  PaymentObject?: string
  Tax: string
  Ean13?: string
}
export interface PaymentReceipt {
  Email?: string
  Phone?: string
  EmailCompany?: string
  Taxation: string
  Items: PaymentItems[]
  Payments?: string
}
