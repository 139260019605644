import axios from 'axios'
import * as authService from '@/services/auth'
import {
  API_URL,
  DIRECTUAL_APP_ID
} from '@/common/constants'

/**
 * Сконфигурированный axios клиент
 * */
const axiosClient = axios.create({
  timeout: 100000,
  baseURL: API_URL,
  params: {
    appID: DIRECTUAL_APP_ID
  },
  headers: {
    'Content-Type': 'application/json'
  },
  maxContentLength: 10000
})

/**
 * Добавление JSON TOKEN в каждый запрос
 * */
axiosClient.interceptors.request.use(
  async config => {
    const authenticationToken = authService.getAccess().accessToken
    if (authenticationToken) {
      config.params.sessionID = authenticationToken
    }
    return config
  },
  error => Promise.reject(error)
)

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    console.warn('error', error)

    if ([403, 500].includes(error?.response?.status)) {
      authService.logout()
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default axiosClient
