import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import {
  Order, OrderProduct, OrdersState, SerializedOrder, SerializedOrderProduct
} from '@/store/modules/orders/types'
import {
  OriginalProduct,
  SerializedOriginalProduct
} from '../products/types'

const getters: GetterTree<OrdersState, RootState> = {
  order: (state, getters, rootState) => {
    return rootState.nutrition?.nutritionBasket?.id ? rootState.nutrition.nutritionBasket : rootState.orders.currentOrder
  },

  getOrder: (state) => (id: string) => {
    return state.orders.find((el) => el.id === id)
  },

  deserializeOrder: (state, getters) => (data: SerializedOrder): Order => ({
    id: data.id,
    status: data.status_id
      ? ({
          id: data.status_id?.id,
          value: data.status_id?.value
        })
      : null,
    basket: data.basket_id
      ? {
          id: data.basket_id?.id,
          name: data.basket_id?.name,
          isKindergarten: data.basket_id?.isKindergarten
        }
      : null,
    deliveryDate: data.deliveryDate,
    deliveryTimeRange: data.deliveryTimeRange,
    orderNumber: data.orderNumber,
    orderDate: data.orderDate,
    isClientPaid: data.isClientPaid || null,
    totalSum: +data.totalSum,
    orderName: data.orderName || data.user_id?.name || null,
    orderPhone: data.orderPhone || data.user_id?.id || null,
    orderEmail: data.orderEmail || null,
    dishes: data.dishes_ids ?? [],
    products: data.products_ids?.map(el => getters.deserializeOrderProduct(el)) ?? [],
    basketName: data.basketName || data.basket_id?.name || null,
    orderAddress: data.orderAddress || null,
    isRecipe: data.isRecipe,
    recipeRegion: data.recipeRegion || null,
    recipeShop: data.recipeShop || null,
    numberOfPersons: data.numberOfPersons || null,
    paymentMethod: data.paymentMethod_id,
    bonusesUsed: data.bonusesUsed || null,
    selectedShop: data.selectedShop_id,
    nutritionBasketId: data.nutritionBasket_id
  }),

  deserializeOrderProduct: () => (data: SerializedOrderProduct): OrderProduct => {
    return {
      id: data.id,
      originalProductId: data.product_id.id,
      name: data.product_id.name,
      category: (data.product_id.category_id && {
        id: data.product_id.category_id.id,
        name: data.product_id.category_id.name
      }) || null,
      package: data.package ?? data.product_id.package,
      producer: data.product_id.producer,
      image: data.product_id.image,
      cost: data.price ?? data.product_id.cost,
      quantityWeight: data.quantityWeight,
      unit_id: {
        value: data.product_id.unit_id.value
      },
      shop: {
        id: data.product_id.shop_id.id,
        name: data.product_id.shop_id.value
      },
      productReplacement: {
        id: data.product_id.productReplacement_id?.id,
        name: data.product_id.productReplacement_id?.value
      },
      originalProduct: data.originalProduct_id
        ? {
            category: {
              id: data.originalProduct_id?.category_id.id,
              name: data.originalProduct_id?.category_id.name
            },
            cost: data.originalProduct_id.cost,
            id: data.originalProduct_id.id,
            image: data.originalProduct_id.image,
            name: data.originalProduct_id.name,
            package: data.originalProduct_id.package,
            producer: data.originalProduct_id.producer,
            shop: {
              id: data.originalProduct_id?.shop_id.id,
              value: data.originalProduct_id?.shop_id.value
            },
            unit_id: {
              id: data.originalProduct_id?.unit_id.id,
              value: data.originalProduct_id?.unit_id.value
            }
          }
        : null,
      weightPerOnePerson: data.weightPerOnePerson,
      quantityPcs: data.quantityPcs,
      totalPrice: data.totalPrice
    }
  },

  serializeOrder: () => (data: Order): SerializedOrder => ({
    id: data.id,
    status_id: {
      id: data.status.id,
      value: data.status.value
    },
    user_id: {
      id: data.orderPhone,
      name: data.orderName
    },
    orderNumber: data.orderNumber,
    orderPhone: data.orderPhone,
    orderEmail: data.orderPhone,
    orderDate: data.orderDate,
    isClientPaid: data.isClientPaid,
    isRecipe: data.isRecipe,
    totalSum: data.totalSum.toString(),
    bonusesUsed: data.bonusesUsed,
    selectedShop_id: data.selectedShop,
    nutritionBasket_id: data.nutritionBasketId
  }),

  serializeOriginalProduct: () => (data: OriginalProduct): SerializedOriginalProduct => ({
    category_id: {
      name: data.category.name,
      id: data.category.id
    },
    cost: data.cost,
    id: data.id,
    image: data.image,
    name: data.image,
    package: data.package,
    producer: data.image,
    shop_id: {
      id: data.shop.id,
      value: data.shop.value
    },
    unit_id: {
      id: data.unit_id.id,
      value: data.unit_id.value
    }
  }),

  serializeOrderProduct: (state, getters) => (data: OrderProduct): SerializedOrderProduct => ({
    id: data.id,
    totalPrice: data.totalPrice,
    product_id: {
      id: data.originalProductId,
      unit_id: {
        value: data.unit_id.value
      },
      name: data.name,
      category_id: data.category && {
        id: data.category.id,
        name: data.category.name
      },
      producer: data.producer,
      image: data.image,
      cost: data.cost,
      package: data.package
    },
    weightPerOnePerson: data.weightPerOnePerson,
    quantityPcs: data.quantityPcs,
    quantityWeight: data.quantityWeight,
    package: data.package,
    price: data.cost,
    originalProduct_id: getters.serializeOriginalProduct(data.originalProduct)
  }),

  isOrder: (state) => {
    return !!state.currentOrder.id
  }
}

export default getters
