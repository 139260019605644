























import {
  mapState,
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex'
import Vue from 'vue'
import ModalLogin from '@/components/ModalLogin.vue'
import ModalServiceNotAvailable from '@/components/ModalServiceNotAvailable.vue'
import TheHeader from '@/components/TheHeader.vue'
import { NavigationItem } from '@/main.types'
import TheFooter from '@/components/TheFooter.vue'
import { isMobile } from 'mobile-device-detect'
import * as authService from '@/services/auth'
import { DEFAULT_SHOP_ID } from '@/common/constants'
import { EventBus } from '@/EventBus'
import { Route } from 'vue-router'

export default Vue.extend({
  name: 'App',

  components: {
    ModalLogin,
    TheHeader,
    TheFooter,
    ModalServiceNotAvailable
  },

  data: () => ({
    adminNav: [
      {
        name: 'admin-products',
        query: { 'filter-shop': DEFAULT_SHOP_ID },
        title: 'Продукты'
      },
      {
        name: 'admin-dishes',
        title: 'Блюда'
      },
      {
        name: 'admin-dishes-categories',
        title: 'Подборки блюд'
      },
      {
        name: 'admin-baskets',
        title: 'Корзины'
      },
      {
        name: 'admin-meal-plans',
        title: 'Планы питания'
      },
      {
        name: 'admin-orders',
        title: 'Заказы'
      },
      {
        name: 'admin-catalogs',
        title: 'Справочники'
      }
    ] as NavigationItem[],
    isMobile: isMobile,
    layoutClass: '',
    checkedAuth: false
  }),

  computed: {
    ...mapState('auth', [
      'user'
    ]),

    ...mapState('baskets', [
      'steps'
    ]),

    ...mapGetters('common', [
      'lightHeader'
    ]),

    clientNav (): NavigationItem[] {
      const links = [
        {
          name: 'home',
          icon: 'bag',
          title: 'Выбрать корзину'
        },
        {
          icon: 'constructor',
          title: 'Конструктор',
          items: [
            {
              name: 'custom-basket',
              title: 'Создать набор блюд'
            },
            {
              name: 'nutrition',
              title: 'Создать план питания'
            }
          ]
        },
        {
          name: 'delivery',
          title: 'Доставка'
        },
        {
          name: 'faq',
          title: 'FAQ'
        }
      ] as NavigationItem[]

      if (this.$route.name === 'home' || this.$route.name === 'kindergarten-landing') links[0].hash = '#baskets'

      return links
    },

    fullWidth (): boolean {
      return this.$route.meta.fullWidth
    },

    layoutName (): string {
      switch (this.$route.meta.layout) {
        case 'admin':
          return 'admin'
        default:
          return 'client'
      }
    },

    lightHeaderClass (): string {
      return this.lightHeader ? 'is-light-header' : null
    },

    navItems () {
      switch (this.layoutName) {
        case 'admin':
          return this.adminNav

        default:
          return this.clientNav
      }
    }
  },

  watch: {
    $route (to: Route) {
      this.layoutClass = `layout-${to.name}`
    },

    layoutClass () {
      const prefix = 'layout-'
      const regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g')
      const body = document.getElementsByTagName('body')[0]

      body.className = body.className.replace(regx, '')
      body.classList.add(this.layoutClass)
    }
  },

  async created () {
    this.injectCarrotQuestCode()
    this.showBslAuthor()
    EventBus.$on('try-checkout-order', this.handleTryCheckout)

    this.layoutClass = `layout-${this.$route.name}`

    const accessToken = authService.getAccess().accessToken

    if (accessToken) {
      await this.checkAuth()
      await this.getClientOrdersDrafts(this.user.id)
    }
    this.checkedAuth = true
    this.saveUrlParams()
    setTimeout(() => {
      this.$bvModal.show('modal-service-not-available')
    }, 100)
  },

  beforeDestroy () {
    EventBus.$off('try-checkout-order', this.handleTryCheckout)
  },

  methods: {
    ...mapActions('auth', [
      'checkAuth'
    ]),

    ...mapActions('orders', [
      'getClientOrdersDrafts'
    ]),

    ...mapMutations('baskets', [
      'setSteps'
    ]),

    ...mapMutations('orders', [
      'setRedirectUrlAfterAuth'
    ]),

    handleTryCheckout (payload) {
      this.setRedirectUrlAfterAuth(payload.fullPath)
      this.$bvModal.show('modal-login')
    },

    saveUrlParams () {
      const urlParams = window.location.search
      localStorage.setItem('url-params', urlParams)
    },

    injectCarrotQuestCode () {
      const script = document.createElement('script')
      script.text = '!function(){function t(t,e){return function(){window.carrotquestasync.push(t,arguments)}}if("undefined"==typeof carrotquest){var e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="//cdn.carrotquest.app/api.min.js",document.getElementsByTagName("head")[0].appendChild(e),window.carrotquest={},window.carrotquestasync=[],carrotquest.settings={};for(var n=["connect","track","identify","auth","oth","onReady","addCallback","removeCallback","trackMessageInteraction"],a=0;a<n.length;a++)carrotquest[n[a]]=t(n[a])}}(),carrotquest.connect("43838-c9c75207495cea821bc0e4649b");'
      document.head.appendChild(script)
    },

    showBslAuthor () {
      // eslint-disable-next-line no-console
      console.log('%c Приходи работать в BSL! ', 'background: #000; color: #ff7303; font-size: 30px;')
      // eslint-disable-next-line no-console
      console.log('%c Ждем тебя мы https://hh.ru/employer/3879484', 'color: #ff7303;')
    }
  }
})
