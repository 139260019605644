import Vue from 'vue'
import {
  NutritionDay,
  NutritionBasket
} from './types'
import { OrderProduct } from '../orders/types'

const mutations = {
  setNutritionBasketId (state, id: string) {
    state.nutritionBasketId = id
  },

  setNutritionBasket (state, payload: NutritionBasket) {
    state.nutritionBasket = payload
  },

  setNutritionBasketProducts (state, payload) {
    Vue.set(state.nutritionBasket, 'products', payload)
  },

  updateNutritionCurrentDay (state, day: NutritionDay) {
    state.nutritionCurrentDay = day
  },

  updateNutritionCurrentDayTimeId (state, dayTimeId: string) {
    state.nutritionCurrentDayTimeId = dayTimeId
  },

  updateNutritionBasketTotalSum (state, summ: number) {
    Vue.set(state.nutritionBasket, 'totalSum', summ)
  },

  updateNutritionBasketProduct (state, payload: {
    productId: string
    quantityPcs: number
    totalPrice: number
  }) {
    const productIndex = state.nutritionBasket.products.findIndex((product: OrderProduct) => product.id === payload.productId || product.originalProductId === payload.productId)
    const product = state.nutritionBasket.products[productIndex]

    product.quantityPcs = payload.quantityPcs
    product.totalPrice = payload.totalPrice

    if (product) {
      Vue.set(state.nutritionBasket.products, productIndex, product)
    }
  },

  resetNutritionBasket (state) {
    state.nutritionBasket = {}
    state.nutritionBasketId = null
    state.nutritionCurrentDay = { id: '', day: 0 }
    state.nutritionCurrentDayTimeId = ''
    state.nutritionBasketProducts = []
  }
}

export default mutations
