import { render, staticRenderFns } from "./BasketsCarousel.vue?vue&type=template&id=486ed122&scoped=true&lang=pug&"
import script from "./BasketsCarousel.vue?vue&type=script&lang=ts&"
export * from "./BasketsCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./BasketsCarousel.vue?vue&type=style&index=0&id=486ed122&lang=scss&scoped=true&"
import style1 from "./BasketsCarousel.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486ed122",
  null
  
)

export default component.exports