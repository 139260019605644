export default {
  savedRegion: localStorage.getItem('region'),
  foodStyles: localStorage.getItem('foodStyles') || '',
  newHomePage: localStorage.getItem('newHomePage') ? JSON.parse(localStorage.getItem('newHomePage')) : false,
  homePageOfferId: +localStorage.getItem('homePageOfferId') || null,
  savedCity: localStorage.getItem('city'),
  route: null,
  loading: false,
  netWorthSwitcherValue: false
}
