import {
  DEFAULT_CATEGORY_NAME, BASKET_CATEGORIES
} from '@/common/constants'
import { cloneDeep } from 'lodash'
import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import {
  BasketsState, Basket, SerializedBasket, BasketProduct, SerializedBasketProduct
} from './types'

const getters: GetterTree<BasketsState, RootState> = {
  basket: (state, getters, rootState) => {
    if (rootState.nutrition.nutritionBasket.id) {
      return rootState.nutrition.nutritionBasket
    }

    if (rootState.orders.currentOrder.id) {
      return rootState.orders.currentOrder
    }
    return state.currentBasket
  },

  getBaskets: (state) => {
    return state.baskets
  },

  getDefaultBasket: () => (): Basket => ({
    name: '',
    basketCategory: DEFAULT_CATEGORY_NAME,
    basketAuthorName: '',
    basketAuthorSpecialisation: '',
    basketAuthorPhone: '',
    basketAuthorEmail: '',
    basketAuthorCommonLink: '',
    basketAuthorFacebook: '',
    basketAuthorInstagram: '',
    basketAuthorDescription: '',
    basketAuthorLogo: '',
    slug: '',
    description: '',
    image: '',
    persons: '',
    personsCountText: '',
    parentBasket_id: '',
    recipes: '',
    sortIndex: '0',
    dishes: [],
    products: [],
    isDraft: false,
    isPopular: false,
    daysCountBasketDefaultText: '7-10 дней',
    daysCountBasketMiniText: '3-5 дней',
    price: 0,
    isKindergarten: false
  }),

  isKindergartenBasket: (state, getters, rootState) => {
    if (state.currentBasket?.id) {
      return state.currentBasket.isKindergarten
    } else if (rootState.orders.currentOrder.basket) {
      return rootState.orders.currentOrder.basket.isKindergarten ?? false
    }
    return false
  },

  basketSelected: (state) => {
    return state.currentBasket.id !== ''
  },

  basketsCategories: (state) => {
    const categories = []
    const baskets = state.baskets.filter((basket: Basket) => !basket.isDraft)

    baskets.map((basket: Basket) => {
      const hasCategory = categories.find(cat => cat.name === basket.basketCategory)

      if (basket.basketCategory && !hasCategory) {
        categories.push({
          name: basket.basketCategory,
          count: 1,
          authors: [{
            name: basket.basketAuthorName,
            image: basket.basketAuthorLogo ?? null
          }]
        })
      }

      if (hasCategory) {
        const categoryIndex = categories.findIndex((cat: { name: string }) => cat.name === basket.basketCategory)

        const hasThisAuthor = categories[categoryIndex].authors.find(author => author.name === basket.basketAuthorName)

        if (!hasThisAuthor) {
          categories[categoryIndex].authors.push({
            name: basket.basketAuthorName,
            image: basket.basketAuthorLogo ? basket.basketAuthorLogo : null
          })
        }
        categories[categoryIndex].count += 1
      }

      return basket
    })

    if (categories.length) {
      const defaultCategory = categories.find(cat => {
        return cat.name === 'от Корзины на дом'
      })
      const defaultCategoryIndex = categories.findIndex(cat => {
        return cat.name === 'от Корзины на дом'
      })

      if (defaultCategoryIndex > -1) {
        categories.splice(defaultCategoryIndex, 1)
        categories.unshift(defaultCategory)
      }
    } else {
      return
    }

    let sortCategories = BASKET_CATEGORIES.map(item => {
      const existCategoy = categories.find(cat => cat.name === item)

      return existCategoy ?? { name: item }
    })

    sortCategories = sortCategories.filter((item: { name: string, count: number }) => item.count)

    return sortCategories
  },

  get3DaysBaskets: (state) => {
    return state.baskets.filter((basket: Basket) => !!basket.parentBasket_id)
  },

  get7DaysBaskets: (state) => {
    return state.baskets.filter((basket: Basket) => !basket.parentBasket_id)
  },

  getBasketsWithDaysVariations: (state) => {
    const baskets = cloneDeep(state.baskets)
    return baskets
      .filter((basket: Basket) => !basket.parentBasket_id)
      .map((basket: Basket) => {
        const obj = basket
        const days3Basket = state.baskets.find((item: Basket) => basket.id === item.parentBasket_id)

        if (days3Basket) {
          obj.days3 = {
            id: days3Basket.id,
            price: days3Basket.price,
            recipes: days3Basket.recipes,
            weight: days3Basket.weight ?? null,
            personsCountText: days3Basket.personsCountText,
            daysCountText: days3Basket.daysCountBasketMiniText ?? '3-5 дней'
          }
        }
        obj.days7 = {
          id: basket.id,
          price: basket.price,
          recipes: basket.recipes,
          weight: basket.weight ?? null,
          personsCountText: basket.personsCountText,
          daysCountText: basket.daysCountBasketDefaultText ?? '7-10 дней'
        }

        delete obj.price
        delete obj.recipes
        delete obj.weight
        delete obj.persons

        return basket
      })
  },

  deserializeBasketProduct: () => (data: SerializedBasketProduct): BasketProduct => {
    return {
      id: data.id,
      originalProductId: data.product_id.id,
      name: data.product_id.name,
      category: (data.product_id.category_id && {
        id: typeof data.product_id.category_id === 'string' ? data.product_id.category_id : data.product_id.category_id.id,
        name: data.product_id.category_id.name || null
      }) || null,
      package: data.package || data.product_id.package,
      producer: data.product_id.producer,
      image: data.product_id.image,
      cost: data.price ?? data.product_id.cost ?? null,
      quantityWeight: data.quantityWeight,
      unit_id: {
        value: data.product_id.unit_id.value
      },
      shop: {
        id: data.product_id.shop_id.id,
        name: data.product_id.shop_id.value
      },
      productReplacement: (data.product_id.productReplacement_id)
        ? {
            id: data.product_id.productReplacement_id?.id,
            name: data.product_id.productReplacement_id?.value
          }
        : null,
      weightPerOnePerson: data.weightPerOnePerson || null,
      quantityPcs: data.quantityPcs,
      totalPrice: data.totalPrice
    }
  },

  deserializeBasket: (state, getters) => (data: SerializedBasket): Basket => ({
    basketCategory: data.basketCategory,
    basketAuthorName: data.basketAuthorName,
    basketAuthorSpecialisation: data.basketAuthorSpecialisation,
    basketAuthorPhone: data.basketAuthorPhone,
    basketAuthorEmail: data.basketAuthorEmail,
    basketAuthorCommonLink: data.basketAuthorCommonLink,
    basketAuthorFacebook: data.basketAuthorFacebook,
    basketAuthorInstagram: data.basketAuthorInstagram,
    basketAuthorDescription: data.basketAuthorDescription,
    basketAuthorLogo: data.basketAuthorLogo,
    daysCountBasketDefaultText: data.daysCountBasketDefaultText,
    daysCountBasketMiniText: data.daysCountBasketMiniText,
    description: data.description,
    headerBg: data.header_bg,
    id: data.id,
    isDraft: data.isDraft,
    isPopular: data.isPopular,
    isKindergarten: data.isKindergarten,
    slug: data.slug,
    image: data.image,
    dishes: data.dishes_ids,
    name: data.name,
    parentBasket_id: data.parentBasket_id,
    persons: data.persons,
    personsCountText: data.personsCountText,
    price: data.price,
    products: data.productInBasket_ids?.map(el => getters.deserializeBasketProduct(el)) ?? [],
    recipes: data.recipes,
    sortIndex: data.sortIndex,
    weight: data.weight
  }),

  serializeBasket: () => (data: Basket): SerializedBasket => {
    const serializedBasket = {
      basketCategory: data.basketCategory,
      basketAuthorName: data.basketAuthorName,
      basketAuthorSpecialisation: data.basketAuthorSpecialisation,
      basketAuthorPhone: data.basketAuthorPhone,
      basketAuthorEmail: data.basketAuthorEmail,
      basketAuthorCommonLink: data.basketAuthorCommonLink,
      basketAuthorFacebook: data.basketAuthorFacebook,
      basketAuthorInstagram: data.basketAuthorInstagram,
      basketAuthorDescription: data.basketAuthorDescription,
      daysCountBasketDefaultText: data.daysCountBasketDefaultText,
      daysCountBasketMiniText: data.daysCountBasketMiniText,
      description: data.description,
      id: data.id,
      isDraft: data.isDraft,
      isPopular: data.isPopular,
      isKindergarten: data.isKindergarten,
      slug: data.slug,
      name: data.name,
      parentBasket_id: data.parentBasket_id,
      persons: data.persons,
      personsCountText: data.personsCountText,
      recipes: data.recipes,
      sortIndex: data.sortIndex,
      weight: data.weight
    } as SerializedBasket

    if (data.basketAuthorLogo instanceof File) {
      serializedBasket.basketAuthorLogo_id = data.basketAuthorLogo
    }

    if (data.image instanceof File) {
      serializedBasket.image_id = data.image
    }

    if (data.headerBg instanceof File) {
      serializedBasket.header_bg_id = data.headerBg
    }

    return serializedBasket
  }
}

export default getters
