import { AuthState } from './types'

export function getInitialAuthText (): { title: string, description: string } {
  return {
    title: 'Вход или регистрация', // Войдите или зарегистрируйтесь, чтобы продолжить
    description: 'Авторизуйтесь или зарегистрируйтесь чтобы получить доступ ко всем возможностям сервиса.' // 'На указанный номер будет отправлен код доступа. После этого у вас будет доступ ко всем возможностям сервиса.'
  }
}

const state: AuthState = {
  user: {
    id: null,
    name: null,
    address: null
  },
  isAuthenticated: false,
  authText: getInitialAuthText()
}

export default state
