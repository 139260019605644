/* eslint-disable camelcase */
import apiClient from '@/services/api/apiClient'
import * as authService from '@/services/auth'
import { ActionTree } from 'vuex'
import {
  Access,
  AuthState,
  AuthText,
  User
} from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<AuthState, RootState> = {
  login ({ commit }, data) {
    commit('setAuth', data)
  },

  async verificateCode (context, payload) {
    return await apiClient.post('WebUser/checkVerificationCode', payload)
  },

  logout ({ commit }) {
    commit('removeAuth')
  },

  changeAuthText ({ commit }, data: AuthText) {
    commit('setAuthText', data)
  },

  resetAuthText ({ commit }) {
    commit('setDefaultAuthText')
  },

  async getUser ({ commit }) {
    try {
      const res = await apiClient.get('WebUserSession/getUser')

      if (!res.data.payload.length) {
        return res
      }

      if (res.status === 200) {
        commit('setUser', res.data.payload[0].webUserId)
      }

      return res
    } catch (error) {
      return { error: error.response.data }
    }
  },

  async checkAuth ({ commit }) {
    const access = authService.getAccess() as Access
    const accessToken = access.accessToken

    if (accessToken) {
      try {
        const res = await apiClient.get('WebUserSession/getUser')

        if (!res.data.payload.length) {
          commit('removeAuth')
          return res
        }

        commit('setAuth', res.data.payload[0].webUserId)
        return res
      } catch (error) {
        return { error: error.response.data }
      }
    } else {
      commit('removeAuth')
    }
  },

  async updateUser ({ commit }, payload: User) {
    try {
      const res = await apiClient.post('WebUser/editUser', payload)

      if (res.status === 200) {
        commit('updateUser', payload)
      }

      return res
    } catch (error) {
      return { error: error.response.data }
    }
  }
}

export default actions
