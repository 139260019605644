








import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'FloatField',

  props: {
    label: {
      type: String as PropType<string>,
      default: '',
      required: false
    },

    labelFor: {
      type: String as PropType<string>,
      default: '',
      required: false
    }
  }
})
