






































































































































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex'

import Logo from '@/components/Logo.vue'
import SocialLinks from '@/components/SocialLinks.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import { NavigationItem } from '@/main.types'
import SelectRegion from '@/components/SelectRegion.vue'
import Consultant from '@/components/Consultant.vue'
import ModalBaskets from '@/components/ModalBaskets.vue'
import yaMetrica from '@/mixins/yaMetrica'
import * as authService from '@/services/auth'
import { DEFAULT_SHOP_ID } from '@/common/constants'

export default Vue.extend({
  name: 'AppHeader',

  components: {
    Logo,
    SocialLinks,
    Consultant,
    SelectRegion,
    ModalBaskets,
    MobileMenu
  },

  mixins: [
    yaMetrica
  ],

  props: {
    navItems: {
      type: Array as PropType<NavigationItem[]>,
      default: () => ([] as NavigationItem[])
    }
  },

  data () {
    return {
      defaultShopId: DEFAULT_SHOP_ID,
      headerFixed: false,
      basketRoutes: [
        'basket',
        'custom-basket',
        'order',
        'order-checkout',
        'nutrition',
        'nutrition-checkout'
      ]
    }
  },

  computed: {
    ...mapState('auth', [
      'isAuthenticated',
      'user'
    ]),

    ...mapState('orders', [
      'draftOrdersCount'
    ]),

    ...mapGetters('common', [
      'lightHeader'
    ]),

    isAdminLayout () {
      return this.$route?.meta?.layout
    },

    logoUrl () {
      return this.lightHeader ? require('@/assets/images/logo-colored-light.png') : null
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {
    ...mapActions('auth', ['logout']),

    handleScroll () {
      const scrollTop = window.scrollY
      const headerEl = this.$refs.header
      const isBasketRoute = this.basketRoutes.includes(this.$route.name)

      if (headerEl instanceof HTMLElement) {
        const headerOffsetTop = headerEl.offsetTop

        this.headerFixed = scrollTop > headerOffsetTop && !isBasketRoute
      }
    },

    onDesktopLink (link: NavigationItem) {
      if (link.name === 'home' && this.$route.name !== 'home') {
        this.$bvModal.show('modal-baskets')
      }

      if (link.title === 'Собрать свою корзину') {
        this.ymTrack('CustomBasket')
      }
    },

    handleLogout () {
      this.logout()
      authService.logout()
      this.$router.push('/')
    }
  }
})
