import { MutationTree } from 'vuex'
import {
  Product, ProductType, ProductCategory, ProductsState
} from './types'
import { ValueIdMapItem } from '@/main.types'

const mutations: MutationTree<ProductsState> = {
  setProducts (state, data: Product[]) {
    state.products = data
  },

  setProductCategories (state, data: ProductCategory[]) {
    state.productCategories = data
  },

  // eslint-disable-next-line @typescript-eslint/ban-types
  setReplacingProduct (state, product: object) {
    state.replacingProduct = product
  },

  updateProduct (state, product: Product) {
    const productIndex = state.products.findIndex(el => el.id === product.id)
    state.products.splice(productIndex, 1, product)
  },

  removeProduct (state, productId: string) {
    const productIndex = state.products.findIndex(el => el.id === productId)
    state.products.splice(productIndex, 1)
  },

  addProduct (state, product: Product) {
    state.products.unshift(product)
  },

  setProductReplacementCatalogue (state, data: ProductType[]) {
    state.productReplacementCatalogue = data
  },

  setShopsCatalogue (state, data: ValueIdMapItem[]) {
    const index = data.findIndex(el => el.id === 'korzinanadom')
    const defaultShop = data[index]
    data.splice(index, 1)
    data.unshift(defaultShop)

    state.shopsCatalogue = data
  },

  createProductType (state, productType: ProductType) {
    state.productReplacementCatalogue.push(productType)
  },

  updateProductType (state, productType: ProductType) {
    const index = state.productReplacementCatalogue.findIndex(el => el.id === productType.id)
    state.productReplacementCatalogue.splice(index, 1, productType)
  },

  removeProductType (state, id: string) {
    const index = state.productReplacementCatalogue.findIndex(el => el.id === id)
    state.productReplacementCatalogue.splice(index, 1)
  },

  setProductsForReplacement (state, data: ProductType[]) {
    state.productsForReplacement = data
  },

  setProductsView (state, view: string) {
    state.productsView = view
    localStorage.setItem('products-view', view)
  }
}

export default mutations
