
















import Vue, { PropType } from 'vue'
import {
  TheMask, mask
} from 'vue-the-mask'

export default Vue.extend({
  name: 'InputMask',
  components: { TheMask },
  directives: {
    mask
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: () => ''
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: () => false
    },
    value: {
      type: String as PropType<string>,
      default: () => ''
    },
    mask: {
      type: String as PropType<string>,
      default: () => ''
    },
    placeholder: {
      type: String as PropType<string>,
      default: 'Телефон'
    },
    type: {
      type: String as PropType<string>,
      default: 'tel'
    },
    state: {
      type: Boolean as PropType<boolean>,
      default: () => true
    }
  },

  data () {
    return {
      tokens: {
        F: {
          pattern: /(?!8)\d/
        },
        '#': {
          pattern: /\d/
        }
      }
    }
  },

  methods: {
    input (val) {
      if (this.value !== val) {
        if (this.mask === '+7 (F##) ###-##-##') {
          this.$emit('input', `7${val}`)
        } else {
          this.$emit('input', val)
        }
      }
    },
    handleChange (val: string) {
      this.$emit('change', val)
    }
  }
})
