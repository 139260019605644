import {
  NutritionBasket,
  SerializedNutritionBasket,
  NutritionDay,
  NutritionDayTime,
  NutritionDish, NutritionState
} from './types'
import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { NUTRITION_DAY_DUMMY } from '@/common/constants'

const getters: GetterTree<NutritionState, RootState> = {
  basketIsAble (state) {
    return state.nutritionBasket?.id && state.nutritionBasket.nutritionClientDays.filter(item => item.id !== null).length === 7
  },

  nutritionCPFC ({ nutritionCurrentDay: day }) {
    return [
      { name: 'Калории', value: day.calories ?? 0 },
      { name: 'Белки', value: day.proteins ?? 0 },
      { name: 'Жиры', value: day.fats ?? 0 },
      { name: 'Углеводы', value: day.carbohydrates ?? 0 }
    ]
  },

  dishesInNutritionPlan ({ nutritionBasket: { nutritionClientDays } }) {
    return nutritionClientDays?.reduce(
      (result: NutritionDish[], item: NutritionDay) => [
        ...result,
        ...(item?.nutritionDayTime_ids?.reduce(
          (result2: NutritionDish[], item2: NutritionDayTime) => [
            ...result2,
            ...(item2.nutritionDish_ids?.filter(item3 => item3.dish_id) ?? [])
          ], []) ?? [])
      ], []
    )
  },

  deserializeNutritionBasket: (state, getters, rootState, rootGetters) => (data: SerializedNutritionBasket): NutritionBasket => {
    return {
      id: data.id,
      nutritionClientDays: data.nutritionClientDay_ids?.length
        ? data.nutritionClientDay_ids
        : Array(7).fill({ ...NUTRITION_DAY_DUMMY }),
      orderId: data.order_id,
      products: data.product_ids?.map(el => rootGetters['baskets/deserializeBasketProduct'](el)) ?? [],
      selectedShop: data.selectedShop_id,
      totalSum: data.totalSum
    }
  }
}

export default getters
