import { ROLE_GUEST } from '@/common/constants'

const ACCESS_KEY = 'auth'

export const setAccess = (accessArg) => {
  localStorage.setItem(ACCESS_KEY, JSON.stringify({
    accessToken: accessArg.accessToken,
    role: accessArg.role ? accessArg.role : ROLE_GUEST
  }))
}

export const getAccess = () => {
  const access = JSON.parse(localStorage.getItem(ACCESS_KEY))
  if (access) return access

  return {
    accessToken: null,
    role: ROLE_GUEST
  }
}

export const logout = () => {
  localStorage.removeItem(ACCESS_KEY)
}

export const getRole = () => getAccess().role

export default {
  setAccess,
  getAccess,
  logout,
  getRole
}
