import apiClient from '@/services/api/apiClient'
import { ActionTree } from 'vuex'
import {
  Dish,
  DishesCategory,
  DishesState,
  DishesSubCategory,
  DishProduct
} from './types'
import { RootState } from '@/store/types'
import {
  getEntityImagesVariants,
  objectToFormData,
  sortArrayByOrderIndex
} from '@/utils/functions'
import { cloneDeep } from 'lodash'

export const actions: ActionTree<DishesState, RootState> = {
  async getDishTypeCatalogue ({ commit }) {
    const res = await apiClient.get('/dishTypeCatalogue/getDishTypeCatalogue', {
      params: { pageSize: 1000 }
    })
    commit('setDishTypeCatalogue', res.data.payload)
    return res
  },

  async getStyleCatalogue ({ commit }) {
    const res = await apiClient.get('/styleCatalogue/getStyleCatalogue', {
      params: { pageSize: 1000 }
    })
    commit('setStyleCatalogue', res.data.payload)
    return res
  },

  async getPreparationMethodCatalogue ({ commit }) {
    const res = await apiClient.get(
      '/preparationMethodCatalogue/getPreparationMethodCatalogue',
      { params: { pageSize: 1000 } }
    )
    commit('setPreparationMethodCatalogue', res.data.payload)
    return res
  },

  async getDishById (
    {
      dispatch,
      getters
    },
    payload: string | { id: string, withProducts?: boolean }
  ) {
    const dishID = typeof payload === 'string' ? payload : payload.id
    const withProducts = typeof payload === 'string' ? false : payload.withProducts

    const { data } = await apiClient.get('/dish/getDishByID', {
      params: {
        dishID,
        pageSize: 1000
      }
    })
    const deserializedDish = getters.deserializeDish(data.payload[0])

    if (withProducts) {
      deserializedDish.products = await dispatch('getDishProducts', dishID)
    }

    return deserializedDish
  },

  async getAllDishes (
    { getters },
    params: {
      name: string
      styles: string
      preparationMethods: unknown[]
      page: number
    }
  ) {
    const { data } = await apiClient.get('/dish/getAllDishes', {
      params: {
        ...params,
        preparationMethods: params.preparationMethods.join(',')
      }
    })

    return {
      ...data,
      payload: data.payload.map(el => getters.deserializeDish(el))
    }
  },

  async createDish ({ getters }, payload: Dish) {
    const dish = getEntityImagesVariants(payload, getters.serializeDish.bind(getters))

    delete dish.serialized.id
    delete dish.serialized.dishSubCategory_ids

    dish.serialized.createDate = Date.now()

    const formData = objectToFormData(dish.serialized)

    const { data } = await apiClient.post(
      '/dish/addDish',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return {
      ...dish.deserialized,
      id: data.result[0].id
    }
  },

  async updateDish ({ getters }, payload: Dish) {
    const dish = getEntityImagesVariants(payload, getters.serializeDish.bind(getters))

    const formData = objectToFormData(dish.serialized)

    const { data } = await apiClient.post(
      '/dish/editDish',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return {
      ...dish.deserialized,
      id: data.result[0].id
    }
  },

  async updateDishSubCategories (context, payload: { dishId: string, dishSubCategoryIds: string }) {
    const res = await apiClient.post('/dish/editDish', {
      id: payload.dishId,
      dishSubCategory_ids: payload.dishSubCategoryIds
    })
    return res
  },

  async deleteDish (context, payload: Dish) {
    const { data } = await apiClient.post(
      '/dish/removeDish',
      {
        id: payload.id
      }
    )

    return data
  },

  async getDishProducts ({ getters }, dishID: string) {
    const { data } = await apiClient.get('/dish/getDishInProducts', {
      params: {
        pageSize: 100,
        dishID
      }
    })

    return data.payload[0].dishProducts_ids?.map(el => getters.deserializeDishProduct(el)) ?? []
  },

  async getDishesByProductOrder (context, payload: {
    productId: string
    orderId: string
  }) {
    const { data } = await apiClient.get('/dishInOrder/getDishesByProductOrder', {
      params: {
        productID: payload.productId,
        orderID: payload.orderId,
        pageSize: 100
      }
    })

    return data.payload
  },

  async getDishesByProductNutrition (context, payload: {
    nutritionBasketId: string
    productId: string
  }) {
    const { data } = await apiClient.get('/nutritionDish/getDishesByProductNutrition', {
      params: {
        nutritionBasketID: payload.nutritionBasketId,
        productID: payload.productId,
        pageSize: 100
      }
    })

    return data.payload
  },

  async getDishesByProductBasket (context, payload: { productId: string, basketId: string }) {
    const { data } = await apiClient.get('/dishesInBasket/getDishesByProductBasket', {
      params: {
        productID: payload.productId,
        basketID: payload.basketId,
        pageSize: 100
      }
    })

    return data.payload
  },

  async addDishProduct ({ getters }, payload: { product: DishProduct, dish: Dish }) {
    const serializedDishProduct = getters.serializeDishProduct(payload.product, payload.dish.id)

    const dishProductId = await apiClient.post('/productForDish/addProductForDish', serializedDishProduct)
      .then(({ data }) => data.result[0].id)

    return {
      ...payload.product,
      id: dishProductId
    }
  },

  async editDishProduct ({ getters }, payload: {
    product: DishProduct
    dish: Dish
  }) {
    const serializedProduct = getters.serializeDishProduct(payload.product, payload.dish.id)

    await apiClient.post('/productForDish/editProductForDish', serializedProduct)

    return cloneDeep(payload.product)
  },

  async setDishInOrderPortions (context, payload: {
    dishId: string
    portions: number
  }) {
    const { data } = await apiClient.post(
      '/dishInOrder/setDishInOrderPortions',
      {
        id: payload.dishId,
        newNumberOfPortions: payload.portions
      }
    )

    return data
  },

  async getMainAndSubDishCategoryList ({ commit }) {
    const { data } = await apiClient.get('/dishMainCategory/getMainAndSubDishCategoryList', {
      params: { pageSize: 1000 }
    })

    const sorteredMainCategories = sortArrayByOrderIndex(data.payload)

    sorteredMainCategories.map((mainCategory: DishesCategory) => {
      mainCategory.dishSubCategory_ids = sortArrayByOrderIndex(mainCategory.dishSubCategory_ids)
      return mainCategory
    })

    commit('setDishesCategories', sorteredMainCategories)
    return data
  },

  async getSubCategoryById ({ commit }, payload: {
    subCategoryId: string
    styles: string
  }) {
    const { data } = await apiClient.get('/dishSubCategory/getSubCategoryById', {
      params: {
        pageSize: 1000,
        dishSubCategoryID: payload.subCategoryId,
        styles: payload.styles
      }
    })

    commit('setSubCategory', data.payload[0])
    return data.payload[0]
  },

  async getMainCategoryDishes (context, dishMainCategoryId: string) {
    const { data } = await apiClient.get('/dishMainCategory/getMainCategoryDishes', {
      params: {
        pageSize: 1000,
        dishMainCategoryID: dishMainCategoryId
      }
    })

    const result = data.payload[0]
    let dishesArray = []

    result.dishSubCategory_ids.forEach(element => {
      dishesArray = element.dishes_ids.concat(dishesArray)
    })
    return dishesArray
  },

  async editSubCategory ({ getters }, subCategory: DishesSubCategory) {
    const serializedDishesSubCategory = getters.serializeDishesSubCategory(subCategory)
    delete serializedDishesSubCategory.dishMainCategory_id

    const formData = objectToFormData(serializedDishesSubCategory)

    await apiClient.post(
      '/dishSubCategory/editSubCategory',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return cloneDeep(subCategory)
  },

  async addDishSubCategory ({ getters }, subCategory: DishesSubCategory) {
    const serializedDishesSubCategory = getters.serializeDishesSubCategory(subCategory)

    const formData = objectToFormData(serializedDishesSubCategory)

    const { data } = await apiClient.post(
      '/dishSubCategory/addDishSubCategory',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    return data.result[0].id
  },

  async removeDishSubCategory (context, id: string) {
    const { data } = await apiClient.post(
      '/dishSubCategory/removeDishSubCategory',
      {
        id
      }
    )

    return data.payload
  }
}

export default actions
