import { AVAILABLE_DELIVERY_REGIONS } from '@/common/constants'

export default {
  deliveryAvailable: (state) => {
    return AVAILABLE_DELIVERY_REGIONS.includes(state.savedRegion)
  },

  lightHeader: (state) => {
    const routeName = state.route?.name
    const routesList = ['basket', 'order', 'custom-basket', 'nutrition']
    return routesList.includes(routeName)
  }
}
