import {
  OrdersState, Order
} from '@/store/modules/orders/types'

const state: OrdersState = {
  currentOrder: {} as Order,
  orders: [],
  orderProducts: [],
  draftOrdersCount: 0,
  saveOrderAfterAuth: '',
  redirectUrlAfterAuth: '',
  dishDetailProducts: [],
  clientOrder: {} as Order,
  checkoutFormIsValid: false,
  startCheckout: false
}

export default state
