const windowWidth = {
  data () {
    return {
      windowWidth: 0
    }
  },

  created () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    }
  }
}

export default windowWidth
