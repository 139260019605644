import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'

import auth from './modules/auth/index'
import common from './modules/common/index'
import baskets from './modules/baskets/index'
import products from './modules/products/index'
import orders from './modules/orders/index'
import payment from './modules/payment/index'
import dishes from './modules/dishes/index'
import nutrition from './modules/nutrition/index'

Vue.use(Vuex)

const storeOptions: StoreOptions<RootState> = {
  modules: {
    auth,
    common,
    baskets,
    products,
    orders,
    payment,
    dishes,
    nutrition
  }
}

const store = new Vuex.Store<RootState>(storeOptions)

export default store
